import {
  BlueprintRoleFragment,
  BlueprintRoleFragmentDoc,
} from '~/services/auth/gql/fragments/blueprintRole.graphql';
import { makeReadFragment, makeUpdateFragment } from '~/services/gql';

/**
 * Reads blueprint role fragment from cache by id
 */
export const readBlueprintRoleFragment =
  makeReadFragment<BlueprintRoleFragment>({
    typeName: 'BlueprintRole',
    fragment: BlueprintRoleFragmentDoc,
  });

/**
 * Update blueprint role in the cache
 */
export const updateBlueprintRoleFragment =
  makeUpdateFragment<BlueprintRoleFragment>({
    typeName: 'BlueprintRole',
    fragment: BlueprintRoleFragmentDoc,
  });
