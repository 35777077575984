import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintRoleFragmentDoc } from '../../../../services/auth/gql/fragments/blueprintRole.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlueprintRolesQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type BlueprintRolesQuery = { __typename?: 'Query', blueprintRoles: { __typename?: 'BlueprintRoleConnection', nodes: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> } };


export const BlueprintRolesDocument = gql`
    query blueprintRoles($search: String) {
  blueprintRoles(search: $search) {
    nodes {
      ...BlueprintRole
    }
  }
}
    ${BlueprintRoleFragmentDoc}`;

/**
 * __useBlueprintRolesQuery__
 *
 * To run a query within a React component, call `useBlueprintRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlueprintRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlueprintRolesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBlueprintRolesQuery(baseOptions?: Apollo.QueryHookOptions<BlueprintRolesQuery, BlueprintRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlueprintRolesQuery, BlueprintRolesQueryVariables>(BlueprintRolesDocument, options);
      }
export function useBlueprintRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlueprintRolesQuery, BlueprintRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlueprintRolesQuery, BlueprintRolesQueryVariables>(BlueprintRolesDocument, options);
        }
export function useBlueprintRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BlueprintRolesQuery, BlueprintRolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BlueprintRolesQuery, BlueprintRolesQueryVariables>(BlueprintRolesDocument, options);
        }
export type BlueprintRolesQueryHookResult = ReturnType<typeof useBlueprintRolesQuery>;
export type BlueprintRolesLazyQueryHookResult = ReturnType<typeof useBlueprintRolesLazyQuery>;
export type BlueprintRolesSuspenseQueryHookResult = ReturnType<typeof useBlueprintRolesSuspenseQuery>;
export type BlueprintRolesQueryResult = Apollo.QueryResult<BlueprintRolesQuery, BlueprintRolesQueryVariables>;