import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ReproductionCrRowsRowFragmentDoc } from './reproductionCrRowsRow.graphql';
import { EmployeeShortFragmentDoc } from '../../../../entities/employees/gql/fragments/employeeShort.graphql';
import { InseminationSchemeFragmentDoc } from '../../../../entities/events/gql/fragments/inseminationScheme.graphql';
import { BullFragmentDoc } from '../../../../entities/bulls/gql/fragments/bull.graphql';
export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByInseminationScheme_Fragment = { __typename: 'ReproductionCrRowsByInseminationScheme', hashID: string, inseminationSchemeRowNames: Array<{ __typename?: 'ReproductionCrRowByInseminationSchemeRowName', inseminationScheme?: { __typename: 'InseminationScheme', id: string, name: string, kind: Types.EventKindEnum, code: string, description: string } | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByAnimalCycle_Fragment = { __typename: 'ReproductionCrRowsByAnimalCycle', hashID: string, animalCycleRowNames: Array<{ __typename?: 'ReproductionCrRowByAnimalCycleRowName', periodStart?: number | null, periodEnd?: number | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByEmployee_Fragment = { __typename: 'ReproductionCrRowsByEmployee', hashID: string, employeeRowNames: Array<{ __typename?: 'ReproductionCrRowByEmployeeRowName', employee?: { __typename: 'Employee', id: string, firstName: string, middleName: string, lastName: string, number: string } | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByDows_Fragment = { __typename: 'ReproductionCrRowsByDows', hashID: string, dowRowNames: Array<{ __typename?: 'ReproductionCrRowByDowsRowName', dow?: Types.Dow | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByDate_Fragment = { __typename: 'ReproductionCrRowsByDate', hashID: string, dateRowNames: Array<{ __typename?: 'ReproductionCrRowByDateRowName', since?: string | null, till?: string | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByIntervalBetweenInseminations_Fragment = { __typename: 'ReproductionCrRowsByIntervalBetweenInseminations', hashID: string, intervalBetweenInseminationsRowNames: Array<{ __typename?: 'ReproductionCrRowByIntervalBetweenInseminationsRowName', periodStart?: number | null, periodEnd?: number | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByBull_Fragment = { __typename: 'ReproductionCrRowsByBull', hashID: string, bullRowNames: Array<{ __typename?: 'ReproductionCrRowByBullRowName', bull?: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByBullBreed_Fragment = { __typename: 'ReproductionCrRowsByBullBreed', hashID: string, bullBreedRowNames: Array<{ __typename?: 'ReproductionCrRowByBullBreedRowName', bullBreed?: { __typename: 'Breed', name: string } | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByInseminationNumber_Fragment = { __typename: 'ReproductionCrRowsByInseminationNumber', hashID: string, inseminationNumberRowNames: Array<{ __typename?: 'ReproductionCrRowByInseminationNumberRowName', inseminationNumber?: number | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByStudCode_Fragment = { __typename: 'ReproductionCrRowsByStudCode', hashID: string, studCodeRowNames: Array<{ __typename?: 'ReproductionCrRowByStudCodeRowName', supplier?: { __typename: 'SemenSupplier', name: string } | null }>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntityFragment = ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByInseminationScheme_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByAnimalCycle_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByEmployee_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByDows_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByDate_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByIntervalBetweenInseminations_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByBull_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByBullBreed_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByInseminationNumber_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByStudCode_Fragment;

export const ReproductionCrDetailedRowsByEntityFragmentDoc = gql`
    fragment ReproductionCrDetailedRowsByEntity on ReproductionCrDetailedRowsByEntity {
  __typename
  hashID
  total {
    ...ReproductionCrRowsRow
  }
  rows {
    ...ReproductionCrRowsRow
  }
  ... on ReproductionCrRowsByDate {
    dateRowNames: rowNames {
      since
      till
    }
  }
  ... on ReproductionCrRowsByStudCode {
    studCodeRowNames: rowNames {
      supplier {
        __typename
        name
      }
    }
  }
  ... on ReproductionCrRowsByInseminationNumber {
    inseminationNumberRowNames: rowNames {
      inseminationNumber
    }
  }
  ... on ReproductionCrRowsByAnimalCycle {
    animalCycleRowNames: rowNames {
      periodStart
      periodEnd
    }
  }
  ... on ReproductionCrRowsByBullBreed {
    bullBreedRowNames: rowNames {
      bullBreed {
        __typename
        name
      }
    }
  }
  ... on ReproductionCrRowsByEmployee {
    employeeRowNames: rowNames {
      employee {
        ...EmployeeShort
      }
    }
  }
  ... on ReproductionCrRowsByIntervalBetweenInseminations {
    intervalBetweenInseminationsRowNames: rowNames {
      periodStart
      periodEnd
    }
  }
  ... on ReproductionCrRowsByInseminationScheme {
    inseminationSchemeRowNames: rowNames {
      inseminationScheme {
        ...InseminationScheme
      }
    }
  }
  ... on ReproductionCrRowsByBull {
    bullRowNames: rowNames {
      bull {
        ...Bull
      }
    }
  }
  ... on ReproductionCrRowsByDows {
    dowRowNames: rowNames {
      dow
    }
  }
}
    ${ReproductionCrRowsRowFragmentDoc}
${EmployeeShortFragmentDoc}
${InseminationSchemeFragmentDoc}
${BullFragmentDoc}`;