import React, { useState } from 'react';

import clsx from 'clsx';
import R from 'ramda';
import * as yup from 'yup';

import { DateInput, DateInputThemes } from '~/shared/components/DateInput';
import { Input, InputThemes } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatDateForBackend } from '~/shared/helpers/date';
import { isTmpId } from '~/shared/helpers/string';

import { BlueprintRoleFragment } from '~/services/auth/gql/fragments/blueprintRole.graphql';
import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { InjectedModalProps, Modal, ModalSizes } from '~/services/modals';
import { Callout, useNotifications } from '~/services/notifications';

import { BLUEPRINT_ROLE_SCHEMA } from '~/entities/blueprintRoles';
import { CreateBlueprintRoleMutation } from '~/entities/blueprintRoles/gql/mutations/createBlueprintRole.graphql';
import { useBlueprintRolesCRUD } from '~/entities/blueprintRoles/hooks';
import { EMPLOYEE_SCHEMA, useEmployeesCRUD } from '~/entities/employees';
import { EmployeeFragment } from '~/entities/employees/gql/fragments/employee.graphql';
import { CreateEmployeeMutation } from '~/entities/employees/gql/mutations/createEmployee.graphql';
import { FARM_SCHEMA, useFarmsCRUD } from '~/entities/farms';
import { FarmFragment } from '~/entities/farms/gql/fragments/farm.graphql';
import { CreateFarmMutation } from '~/entities/farms/gql/mutations/createFarm.graphql';
import { USER_SCHEMA, useUsersCRUD } from '~/entities/users';
import { CreateCompanyUserMutation } from '~/entities/users/gql/mutations/createCompanyUser.graphql';

import panelStyles from '~/styles/modules/panel.module.scss';

import { TimeZoneSelect } from '../../components';
import { TIME_ZONES_DICT } from '../../constants';
import { CompanyDetailedFragment } from '../../gql/fragments/companyDetailed.graphql';
import { useCreateCompanyMutation } from '../../gql/mutations/createCompany.graphql';
import { useUpdateCompanyMutation } from '../../gql/mutations/updateCompany.graphql';
import {
  updateCompanyDetailedFragment,
  updateCompanyFragment,
} from '../../helpers';
import { useDetailedCompany } from '../../hooks';
import { AnyCompanyFragment } from '../../types';
import { useUploadCowFileModal } from '..';
import { EditEmployeesArrayFieldsForm } from './components/EditEmployeesArrayFieldsForm';
import { EditFarmsArrayFieldsForm } from './components/EditFarmsArrayFieldsForm';
import { EditRolesArrayFieldsForm } from './components/EditRolesArrayFieldsForm';
import { EditUsersArrayFieldsForm } from './components/EditUsersArrayFieldsForm';
import styles from './index.module.scss';

export interface EditCompanyModalProps
  extends InjectedModalProps<EditCompanyModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing company, if not passed, a new one is created
   */
  company?: AnyCompanyFragment;
}

const FORM_ID = 'EditCompanyForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
  licensedBy: yup.string().required(), // DateTime!
  tzIdentifier: yup
    .mixed<string>()
    .oneOf(Object.keys(TIME_ZONES_DICT))
    .default(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .required(),
  farms: yup.array(FARM_SCHEMA),
  blueprintRoles: yup.array(BLUEPRINT_ROLE_SCHEMA),
  users: yup.array(USER_SCHEMA),
  employees: yup.array(EMPLOYEE_SCHEMA),
});

export type EditCompanyFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type EditCompanyFormTransformedType = InferValidatedSchema<typeof SCHEMA>;

const mapRoleToForm = (role: BlueprintRoleFragment) =>
  R.pick(['id', 'name', 'color'], role);

const mapFarmToForm = (farm: FarmFragment) =>
  R.pick(['id', 'name', 'number'], farm);

const mapUserToForm = (
  userEdge: CompanyDetailedFragment['users']['edges'][number],
  companyId: string
) => ({
  ...R.pick(
    ['id', 'firstName', 'lastName', 'middleName', 'email'],
    userEdge.node
  ),
  blueprintRoleIDs:
    userEdge.node.companies.edges
      .find(companyEdge => companyEdge.node.id === companyId)
      ?.blueprintRoles?.map(({ id }) => id) ?? [],
  farmIDs: userEdge.farmsInCompany.map(R.prop('id')),
});

const mapEmployeeToForm = (employee: EmployeeFragment) =>
  R.pick(
    ['id', 'number', 'firstName', 'middleName', 'lastName', 'comment'],
    employee
  );

const mapDetailedCompanyToForm = (
  companyDetailed: CompanyDetailedFragment | undefined
) => ({
  ...SCHEMA.getDefault(),
  ...R.pick(
    ['name', 'licensedBy', 'tzIdentifier'],
    companyDetailed ?? ({} as CompanyDetailedFragment)
  ),
  farms: companyDetailed?.farms.nodes.map(mapFarmToForm) ?? [],
  blueprintRoles: companyDetailed?.blueprintRoles.map(mapRoleToForm) ?? [],
  users:
    companyDetailed?.users.edges.map(userEdges =>
      mapUserToForm(userEdges, companyDetailed?.id)
    ) ?? [],
  employees: companyDetailed?.employees.nodes.map(mapEmployeeToForm) ?? [],
});

export const EditCompanyModal: React.FC<EditCompanyModalProps> = ({
  className,
  company,
  close,
}) => {
  const { sendSuccessToast } = useNotifications();

  const isEditing = !!company;

  const { open: openUploadCowFileModal } = useUploadCowFileModal();

  const { isDetailedCompanyLoading, companyDetailed, companyDetailedPromise } =
    useDetailedCompany(company);

  const formContext = useForm<
    EditCompanyFormType,
    EditCompanyFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: () =>
      (isEditing ? companyDetailedPromise : Promise.resolve(undefined)).then(
        mapDetailedCompanyToForm
      ),
  });

  // Use custom loading state, cause we have chained mutations
  const [isUpdateLoading, setUpdateLoading] = useState(false);

  const [createCompany] = useCreateCompanyMutation();
  const [updateCompany] = useUpdateCompanyMutation();

  const { createFarm, updateFarm, deleteFarm } = useFarmsCRUD();

  const {
    mapFarmIdsToCache,
    mapRoleIdToCache,
    tempIdsMapRef,
    createUser,
    updateUser,
    deleteUser,
  } = useUsersCRUD();

  const { updateBlueprintRole, createBlueprintRole } = useBlueprintRolesCRUD();

  const { createEmployee, updateEmployee, deleteEmployee } = useEmployeesCRUD();

  const shouldShowTimeZoneCallout =
    formContext.getFieldState('tzIdentifier').isDirty;

  const handleSubmit = async (form: EditCompanyFormTransformedType) => {
    const submitBlueprintRoles = (companyId: string) => {
      const blueprintRolesToCreate =
        form.blueprintRoles?.filter(roleForm => isTmpId(roleForm.id)) ?? [];
      const blueprintRolesToUpdate =
        form.blueprintRoles?.filter(roleForm => {
          if (isTmpId(roleForm.id)) {
            return false;
          }
          const initialRole = companyDetailed?.blueprintRoles.find(
            role => role.id === roleForm.id
          );
          if (!initialRole) {
            return false;
          }

          return !R.equals(mapRoleToForm(initialRole), roleForm);
        }) ?? [];

      const blueprintRolesToCreatePromises = blueprintRolesToCreate.map(
        roleForm =>
          createBlueprintRole(companyId, roleForm, {
            update: updateCompanyDetailedFragment<CreateBlueprintRoleMutation>(
              companyId,
              (draft, { mutationData }) => {
                draft.blueprintRoles.push(mutationData.createBlueprintRole);
              }
            ),
          }).then(mutationResult => {
            tempIdsMapRef.current[roleForm.id] =
              mutationResult.data?.createBlueprintRole.id;
          })
      );

      const blueprintRolesToUpdatePromises = blueprintRolesToUpdate.map(
        blueprintRoleToUpdate =>
          updateBlueprintRole(companyId, blueprintRoleToUpdate)
      );

      return Promise.all([
        ...blueprintRolesToCreatePromises,
        ...blueprintRolesToUpdatePromises,
      ]);
    };

    const submitFarms = (companyId: string) => {
      const initialFarmIds =
        companyDetailed?.farms.nodes.map(R.prop('id')) ?? [];
      const farmsToCreate =
        form.farms?.filter(farmForm => isTmpId(farmForm.id)) ?? [];
      const farmsToUpdate =
        form.farms?.filter(farmForm => {
          if (isTmpId(farmForm.id)) {
            return false;
          }

          const initialFarm = companyDetailed?.farms.nodes.find(
            farm => farm.id === farmForm.id
          );
          if (!initialFarm) {
            return false;
          }

          return !R.equals(mapFarmToForm(initialFarm), farmForm);
        }) ?? [];

      const farmIdsToDelete = initialFarmIds.filter(
        farmId => !form.farms?.some(farmForm => farmForm.id === farmId)
      );

      const farmsToCreatePromises = farmsToCreate.map(farmForm =>
        createFarm(companyId, farmForm, {
          update: updateCompanyDetailedFragment<CreateFarmMutation>(
            companyId,
            (draft, { mutationData }) => {
              draft.farms.nodes.push(mutationData.createFarm);
            }
          ),
        }).then(mutationResult => {
          tempIdsMapRef.current[farmForm.id] =
            mutationResult.data?.createFarm.id;
        })
      );

      const farmsToUpdatePromises = farmsToUpdate.map(updateFarm);

      const farmsToDeletePromises = farmIdsToDelete.map(farmId =>
        deleteFarm(farmId, {
          update: updateCompanyDetailedFragment(companyId, draft => {
            const deletedFarmIndex = draft.farms.nodes.findIndex(
              f => f.id === farmId
            );
            draft.farms.nodes.splice(deletedFarmIndex);
          }),
        })
      );

      return Promise.all([
        ...farmsToCreatePromises,
        ...farmsToUpdatePromises,
        ...farmsToDeletePromises,
      ]);
    };

    const submitUsers = (companyId: string) => {
      const initialUserIds =
        companyDetailed?.users.edges.map(edge => edge.node.id) ?? [];
      const usersToCreate =
        form.users?.filter(userForm => isTmpId(userForm.id)) ?? [];
      const usersToUpdate =
        form.users?.filter(userForm => {
          if (isTmpId(userForm.id)) {
            return false;
          }

          const initialUserEdge = companyDetailed?.users.edges.find(
            userEdge => userEdge.node.id === userForm.id
          );
          if (!initialUserEdge) {
            return false;
          }

          return !R.equals(mapUserToForm(initialUserEdge, companyId), userForm);
        }) ?? [];

      const userIdsToDelete = initialUserIds.filter(
        userId => !form.users?.some(userForm => userForm.id === userId)
      );

      const usersToCreatePromises = usersToCreate.map(userForm =>
        createUser(companyId, userForm, {
          update: updateCompanyDetailedFragment<CreateCompanyUserMutation>(
            companyId,
            (draft, { mutationData }) => {
              draft.users.edges.push({
                farmsInCompany: mapFarmIdsToCache(userForm.farmIDs),
                node: mutationData.createCompanyUser,
              });
            }
          ),
        })
      );

      const usersToUpdatePromises = usersToUpdate.map(userForm =>
        updateUser(companyId, userForm, {
          update: updateCompanyDetailedFragment(companyId, draft => {
            const updatingEdge = draft.users.edges.find(
              edge => edge.node.id === userForm.id
            );
            if (updatingEdge) {
              const userCompany = updatingEdge.node.companies.edges.find(
                companyEdge => companyEdge.node.id === companyId
              );
              if (Array.isArray(userCompany?.blueprintRoles)) {
                userCompany.blueprintRoles = mapRoleIdToCache(
                  userForm.blueprintRoleIDs
                );
              }

              updatingEdge.farmsInCompany = mapFarmIdsToCache(userForm.farmIDs);
            }
          }),
          refetchQueries: ['myUser'],
        })
      );

      const usersToDeletePromises = userIdsToDelete.map(userId =>
        deleteUser(companyId, userId, {
          update: updateCompanyDetailedFragment(companyId, draft => {
            const deletedUserIndex = draft.users.edges.findIndex(
              u => u.node.id === userId
            );
            draft.users.edges.splice(deletedUserIndex);
          }),
        })
      );

      return Promise.all([
        ...usersToCreatePromises,
        ...usersToUpdatePromises,
        ...usersToDeletePromises,
      ]);
    };

    const submitEmployees = (companyId: string) => {
      const initialEmployeeIds =
        companyDetailed?.employees.nodes.map(R.prop('id')) ?? [];
      const employeesToCreate =
        form.employees?.filter(employeeForm => isTmpId(employeeForm.id)) ?? [];
      const employeesToUpdate =
        form.employees?.filter(employeeForm => {
          if (isTmpId(employeeForm.id)) {
            return false;
          }

          const initialEmployee = companyDetailed?.employees.nodes.find(
            employee => employee.id === employeeForm.id
          );
          if (!initialEmployee) {
            return false;
          }

          return !R.equals(mapEmployeeToForm(initialEmployee), employeeForm);
        }) ?? [];

      const employeeIdsToDelete = initialEmployeeIds.filter(
        employeeId =>
          !form.employees?.some(employeeForm => employeeForm.id === employeeId)
      );

      const employeesToCreatePromises = employeesToCreate.map(employeeForm =>
        createEmployee(companyId, employeeForm, {
          update: updateCompanyDetailedFragment<CreateEmployeeMutation>(
            companyId,
            (draft, { mutationData }) => {
              draft.employees.nodes.push(mutationData.createEmployee);
            }
          ),
        })
      );

      const employeesToUpdatePromises = employeesToUpdate.map(updateEmployee);

      const employeesToDeletePromises = employeeIdsToDelete.map(employeeId =>
        deleteEmployee(employeeId, {
          update: updateCompanyDetailedFragment(companyId, draft => {
            const deletedEmployeeIndex = draft.employees.nodes.findIndex(
              f => f.id === employeeId
            );
            draft.employees.nodes.splice(deletedEmployeeIndex);
          }),
        })
      );

      return Promise.all([
        ...employeesToCreatePromises,
        ...employeesToUpdatePromises,
        ...employeesToDeletePromises,
      ]);
    };

    setUpdateLoading(true);
    try {
      let updatedCompany = companyDetailed;

      const companyForm = {
        name: form.name,
        licensedBy: formatDateForBackend(form.licensedBy),
        tzIdentifier: form.tzIdentifier,
      };
      if (isEditing) {
        await updateCompany({
          variables: {
            id: company.id,
            input: companyForm,
          },
          optimisticResponse: { updateCompany: null },
          update: updateCompanyFragment(company.id, draft => {
            draft.name = form.name;
            draft.licensedBy = form.licensedBy;
            draft.tzIdentifier = form.tzIdentifier;
          }),
        });
      } else {
        const res = await createCompany({
          variables: {
            input: companyForm,
          },
          refetchQueries: ['companies', 'myUser'],
        });
        updatedCompany = res.data?.createCompany;
      }

      if (updatedCompany) {
        await submitBlueprintRoles(updatedCompany.id);
        await submitFarms(updatedCompany.id);
        await Promise.all([
          submitUsers(updatedCompany.id),
          submitEmployees(updatedCompany.id),
        ]);

        sendSuccessToast(
          isEditing
            ? 'Информация о компании сохранена'
            : {
                message: 'Компания создана',
                functionButtonProps: {
                  children: 'Загрузить данные',
                  onPress: () =>
                    openUploadCowFileModal({ company: updatedCompany }),
                },
              }
        );
      }

      close();
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <Modal
      {...{
        className: clsx(styles.root, className),
        size: ModalSizes.large1192,
        title: isEditing ? 'Редактирование компании' : 'Создание компании',
        submitButtonProps: {
          form: FORM_ID,
          isLoading: isUpdateLoading,
          children: isEditing ? 'Сохранить' : 'Создать',
        },
        isLoading: isDetailedCompanyLoading,
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          className: 'grid gap-24',
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <div className={clsx(panelStyles.largeDarkPanel, 'grid gap-16 p-16')}>
          <Typography tag="h3" variant={TypographyVariants.bodyMediumStrong}>
            Основные настройки
          </Typography>

          {shouldShowTimeZoneCallout && (
            <Callout message="Новый часовой пояс будет применяться только для новых событий. Существующая информация останется без изменений, в старом часовом поясе" />
          )}
          <div className="flex items-start gap-16">
            <Input
              {...{
                name: 'name',
                className: 'default-control',
                label: 'Название компании',
                theme: InputThemes.light,
              }}
            />
            <DateInput
              {...{
                name: 'licensedBy',
                className: 'default-control',
                label: 'Дата окончания лицензии',
                theme: DateInputThemes.light,
              }}
            />
            <TimeZoneSelect
              {...{
                name: 'tzIdentifier',
                label: 'Часовой пояс',
                className: 'default-control',
                theme: SelectThemes.light,
              }}
            />
          </div>
        </div>

        <div className={clsx(panelStyles.largeDarkPanel, 'p-16')}>
          <Typography tag="h3" variant={TypographyVariants.bodyMediumStrong}>
            Фермы
          </Typography>

          <EditFarmsArrayFieldsForm className="mt-16" />
        </div>

        <div className={clsx(panelStyles.largeDarkPanel, 'p-16')}>
          <Typography tag="h3" variant={TypographyVariants.bodyMediumStrong}>
            Должности
          </Typography>

          <EditRolesArrayFieldsForm className="mt-16" />
        </div>

        <div className={clsx(panelStyles.largeDarkPanel, 'p-16')}>
          <Typography tag="h3" variant={TypographyVariants.bodyMediumStrong}>
            Пользователи
          </Typography>

          <EditUsersArrayFieldsForm className="mt-16" />
        </div>

        <div className={clsx(panelStyles.largeDarkPanel, 'p-16')}>
          <Typography tag="h3" variant={TypographyVariants.bodyMediumStrong}>
            Сотрудники
          </Typography>

          <EditEmployeesArrayFieldsForm className="mt-16" />
        </div>
      </Form>
    </Modal>
  );
};
