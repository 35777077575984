import {
  ConceptionRateParameterEnum,
  FemaleAnimalKind,
  ReproductionRowsXAxisStep,
  ReproductionYAxisMode,
} from '@graphql-types';

/**
 * Domain specific constant for hdr, pr ad cr calculation periods
 */
export const REPRODUCTION_CALCULATION_PERIOD_DAYS = 21;

/**
 * Dict with domain specific female animal kinds
 */
export const FEMALE_ANIMAL_KINDS_DICT: Record<FemaleAnimalKind, string> = {
  [FemaleAnimalKind.Cow]: 'Коровы',
  [FemaleAnimalKind.Heifer]: 'Тёлки',
};

/**
 * Dict with y axis modes, used in reproduction
 */
export const REPRODUCTION_Y_AXIS_MODE_DICT: Record<
  ReproductionYAxisMode,
  string
> = {
  [ReproductionYAxisMode.ClosestToMax]: 'Ближайшее к максимальному',
  [ReproductionYAxisMode.Percent_100]: '100%',
};
/**
 * Dict with date x axis possible steps, used in reproduction
 */
export const REPRODUCTION_ROWS_X_AXIS_STEP_DICT: Record<
  ReproductionRowsXAxisStep,
  string
> = {
  [ReproductionRowsXAxisStep.Day]: 'День',
  [ReproductionRowsXAxisStep.Week]: 'Неделя',
  [ReproductionRowsXAxisStep.Month]: 'Месяц',
};

/**
 * Dict with all possible conception rate grouping parameters
 */
export const REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT: Record<
  ConceptionRateParameterEnum,
  string
> = {
  [ConceptionRateParameterEnum.Bull]: 'Бык',
  [ConceptionRateParameterEnum.Date]: 'Дата',
  [ConceptionRateParameterEnum.Employee]: 'Техник',
  [ConceptionRateParameterEnum.InseminationScheme]: 'Схема осеменения',
  [ConceptionRateParameterEnum.InseminationNumber]:
    'Номер осеменения в лактации',
  [ConceptionRateParameterEnum.Stud]: 'Код поставщика семени',
  [ConceptionRateParameterEnum.IntervalBetweenInseminations]:
    'Интервал между осеменениями',
  [ConceptionRateParameterEnum.DayOfWeek]: 'День недели',
  [ConceptionRateParameterEnum.AnimalCycle]: 'Цикл животного',
  [ConceptionRateParameterEnum.BullBreed]: 'Порода быка',
};
