import React from 'react';

import { Badge } from '~/shared/components/Badge';
import { IconVariants } from '~/shared/components/Icon';
import { Tooltip } from '~/shared/components/Tooltip';

import { BlueprintRoleFragment } from '~/services/auth/gql/fragments/blueprintRole.graphql';

import { BLUEPRINT_ROLE_COLORS_CONFIGS } from '~/entities/blueprintRoles';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Blueprint roles of CustomReportFragment
   */
  blueprintRoles?: BlueprintRoleFragment[];
}

export const BlueprintRoleBadges: React.FC<Props> = ({ blueprintRoles }) => {
  if (!blueprintRoles?.length) {
    return null;
  }

  const [firstBlueprintRole, ...otherBlueprintRoles] = blueprintRoles;

  return (
    <div className="flex gap-8 items-center full-width">
      <Badge
        iconVariant={IconVariants.dot}
        color={
          BLUEPRINT_ROLE_COLORS_CONFIGS[firstBlueprintRole.color].colorVariant
        }
      >
        {firstBlueprintRole.name}
      </Badge>
      {!!otherBlueprintRoles.length && (
        <Tooltip
          {...{
            content: (
              <div className="grid gap-8">
                {otherBlueprintRoles.map(blueprintRole => (
                  <Badge
                    key={blueprintRole.id}
                    {...{
                      iconVariant: IconVariants.dot,
                      color:
                        BLUEPRINT_ROLE_COLORS_CONFIGS[blueprintRole.color]
                          .colorVariant,
                    }}
                  >
                    {blueprintRole.name}
                  </Badge>
                ))}
              </div>
            ),
          }}
        >
          <Badge isPill>{`+${otherBlueprintRoles.length}`}</Badge>
        </Tooltip>
      )}
    </div>
  );
};
