import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintRoleFragmentDoc } from '../../../../services/auth/gql/fragments/blueprintRole.graphql';
export type UserFragment = { __typename: 'User', id: string, email: any, firstName: string, lastName: string, middleName: string, companies: { __typename?: 'UserCompanyConnection', edges: Array<{ __typename?: 'UserCompanyEdge', node: { __typename: 'Company', id: string }, blueprintRoles?: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> | null }> }, integrator?: { __typename: 'Integrator', id: string, name: string } | null };

export const UserFragmentDoc = gql`
    fragment User on User {
  __typename
  id
  email
  firstName
  lastName
  middleName
  companies {
    edges {
      node {
        __typename
        id
      }
      blueprintRoles {
        ...BlueprintRole
      }
    }
  }
  integrator {
    __typename
    id
    name
  }
}
    ${BlueprintRoleFragmentDoc}`;