import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ReproductionHdrAndPrRowsByDateRowFragment = { __typename: 'ReproductionHdrAndPrRowsByDateRow', date: string, hashID: string, fitForInsemination?: number | null, inseminated?: number | null, hdr?: number | null, fitForPregnancy?: number | null, pregnant?: number | null, pr?: number | null, notPregnant?: number | null, cr?: number | null, abortions?: number | null };

export const ReproductionHdrAndPrRowsByDateRowFragmentDoc = gql`
    fragment ReproductionHdrAndPrRowsByDateRow on ReproductionHdrAndPrRowsByDateRow {
  __typename
  date
  hashID
  fitForInsemination
  inseminated
  hdr
  fitForPregnancy
  pregnant
  pr
  notPregnant
  cr
  abortions
}
    `;