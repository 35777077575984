import React, { FC } from 'react';

import R from 'ramda';

import { ButtonVariants } from '~/shared/components/Button';
import {
  isSkeletonPlaceholder,
  Skeleton,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { formatDateRange } from '~/shared/helpers/date';

import {
  ReportCardBuilderCard,
  useReportCardBuilder,
} from '~/features/reportCardBuilder';

import { SOMATIC_CELLS_REPORT_SETTINGS_FORM_ID } from '../../constants';
import { SomaticCellsReportFragment } from '../../gql/fragments/somaticCellsReport.graphql';
import { useCalculateSomaticCellsReportMutation } from '../../gql/mutations/calculateSomaticCellsReport.graphql';
import { useSetSomaticCellsReportSettingsMutation } from '../../gql/mutations/setSomaticCellsReportSettings.graphql';
import {
  isSomaticCellsReportDataEmpty,
  mapSomaticCellsReportSettingsToForm,
} from '../../helpers';
import { SomaticCellsReportSettingsFormType } from '../../types';
import { SomaticCellsReportData } from '../SomaticCellsReportData';
import { SomaticCellsReportSettingsForm } from '../SomaticCellsReportSettingsForm';

interface Props {
  /**
   * Somatic cells report to render. It can be a fragment or a skeleton
   */
  somaticCellsReport: SomaticCellsReportFragment | SkeletonPlaceholder;
}

export const SomaticCellsReportCardBuilderCard: FC<Props> = ({
  somaticCellsReport,
}) => {
  const [
    setSomaticCellsReportSettings,
    { loading: isSetSomaticCellsReportSettingsLoading },
  ] = useSetSomaticCellsReportSettingsMutation();

  const [
    calculateSomaticCellsReport,
    { reset: resetCalculateSomaticCellsReportMutation },
  ] = useCalculateSomaticCellsReportMutation();

  const mapSomaticCellsReportSettingsFormToBackendInput = (
    settingsForm: SomaticCellsReportSettingsFormType
  ) => ({
    ...R.omit(['period'], settingsForm),
    ...settingsForm.period,
  });

  const {
    currentReportData,

    initialSettings,
    setCurrentReportData,

    customReportCardProps,
    customReportSettingsFormProps,
  } = useReportCardBuilder({
    reportDeps: [somaticCellsReport],

    getReportData: ([somaticCellsReportDep]) =>
      somaticCellsReportDep.calculatedReport,
    getReportSettings: ([somaticCellsReportDep]) =>
      somaticCellsReportDep.settings,
    mapReportSettingsToForm: mapSomaticCellsReportSettingsToForm,

    getIsReportEmpty: somaticCellsReportData =>
      isSomaticCellsReportDataEmpty(somaticCellsReportData),
    emptyReportData: {
      __typename: 'SomaticCellsReportChartEmpty',
      dates: [],
    },

    calculateReport: (settingsFormValues, mutationContext) =>
      calculateSomaticCellsReport({
        variables: {
          id: somaticCellsReport.id,
          settings:
            mapSomaticCellsReportSettingsFormToBackendInput(settingsFormValues),
        },
        context: mutationContext,
      }).then(({ data }) => {
        if (data?.calculateSomaticCellsReport.__typename) {
          setCurrentReportData(data.calculateSomaticCellsReport);
        }
      }),

    setReportSettings: settingsForm =>
      setSomaticCellsReportSettings({
        variables: {
          id: somaticCellsReport.id,
          input: mapSomaticCellsReportSettingsFormToBackendInput(settingsForm),
        },
      }),

    onCardEditCancel: resetCalculateSomaticCellsReportMutation,
  });

  return (
    <ReportCardBuilderCard
      {...{
        title: formatDateRange(initialSettings?.since, initialSettings?.till),
        updatingTitle: 'Настройки',
        ...customReportCardProps,
        settingsButtonProps: {
          variant: ButtonVariants.primary,
          children: 'Настройки',
        },
        submitButtonProps: {
          form: SOMATIC_CELLS_REPORT_SETTINGS_FORM_ID,
          isLoading: isSetSomaticCellsReportSettingsLoading,
        },
        renderEditingContent: () =>
          !isSkeletonPlaceholder(somaticCellsReport) && (
            <SomaticCellsReportSettingsForm
              {...{
                somaticCellsReport,
                ...customReportSettingsFormProps,
              }}
            />
          ),
      }}
    >
      <Skeleton isLoading={isSkeletonPlaceholder(currentReportData)}>
        <SomaticCellsReportData reportData={currentReportData} />
      </Skeleton>
    </ReportCardBuilderCard>
  );
};
