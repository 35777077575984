import { ReproductionRowsXAxisStep } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { REPRODUCTION_ROWS_X_AXIS_STEP_DICT } from '../../constants';

const useReproductionRowsXAxisStepSelect = makeUseEnumSelect(
  ReproductionRowsXAxisStep,
  enumValue =>
    REPRODUCTION_ROWS_X_AXIS_STEP_DICT[enumValue as ReproductionRowsXAxisStep]
);

export const ReproductionRowsXAxisStepSelect = makeSelectComponentFromHook(
  useReproductionRowsXAxisStepSelect
);
