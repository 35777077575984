import React from 'react';

import { RunType, ViewKindEnum } from '@graphql-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';

import { Button, ButtonVariants } from '~/shared/components/Button';
import {
  DateSwitcher,
  DateSwitcherThemes,
} from '~/shared/components/DateSwitcher';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputThemes, InputVariants } from '~/shared/components/Input';
import { Select, SelectThemes } from '~/shared/components/Select';

import { useArkaNavigation } from '~/services/navigation';

import { useBlueprintRoleSelect } from '~/entities/blueprintRoles';

import { PageHeader } from '~/features/layouts';

import { UseBlueprintFiltersInterface } from '../../hooks';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Title to display
   */
  title: string;
  /**
   * Blueprints run type
   */
  runType: RunType;
  /**
   * Hook result for managing filters
   */
  blueprintFiltersInterface: UseBlueprintFiltersInterface;
}

const VIEW_KIND_SELECT_ITEMS = [
  {
    id: null,
    name: 'Все типы карточек',
  },
  {
    id: ViewKindEnum.Table,
    name: 'Список',
  },
  {
    id: ViewKindEnum.Action,
    name: 'Ввод данных',
  },
];

export const BlueprintsPageHeader: React.FC<Props> = ({
  className,
  title,
  runType,
  blueprintFiltersInterface: {
    roleIds,
    setRoleIds,
    viewKind,
    setViewKind,
    search,
    setSearch,
    blueprintsDate,
    setBlueprintsDate,
  },
}) => {
  const {
    navigate,
    urlCompanyId,
    isIntegratorInSelectedCompany: canEdit,
  } = useArkaNavigation();

  const { renderSelectElement: renderBlueprintRoleSelectElement } =
    useBlueprintRoleSelect({
      selectProps: {
        name: 'blueprintRoles',
        isMulti: true,
        rawValue: roleIds,
        className: styles.filter,
        theme: SelectThemes.light,
        onValueChange: newRoleIds => setRoleIds(newRoleIds.map(R.prop('id'))),
        isUsedAsFilter: true,
      },
    });

  return (
    <div className={clsx(styles.root, className)}>
      <PageHeader
        {...{
          title,
          rightContent: canEdit && (
            <>
              <Button
                {...{
                  className: 'ml-a',
                  onPress: () => {
                    navigate({
                      to: '/$companyId/integrator/blueprints/edit/master',
                      params: { companyId: urlCompanyId },
                    });
                  },
                  iconVariant: IconVariants.edit,
                  variant: ButtonVariants.secondary,
                }}
              >
                Редактировать общие поля
              </Button>
              <Button
                {...{
                  iconVariant: IconVariants.plus,
                  onPress: () => {
                    navigate({
                      to: '/$companyId/integrator/blueprints/$blueprintId',
                      params: {
                        blueprintId: 'new',
                        companyId: urlCompanyId,
                      },
                      search: {
                        runType,
                      },
                    });
                  },
                }}
              >
                Создать карточку
              </Button>
            </>
          ),
        }}
      />

      <div className="mt-24 flex items-end gap-16">
        <DateSwitcher
          {...{
            label: 'Дата составления списков',
            labelProps: {
              tooltip: 'Списки будут составляться на выбранную дату',
            },
            name: 'blueprintsDate',
            className: styles.filter,
            isHighlighted: !dayjs().isSame(blueprintsDate, 'day'),
            value: blueprintsDate,
            theme: DateSwitcherThemes.light,
            onValueChange: setBlueprintsDate,
          }}
        />
        {renderBlueprintRoleSelectElement()}
        <Select<(typeof VIEW_KIND_SELECT_ITEMS)[number]>
          {...{
            name: 'viewKind',
            className: styles.filter,
            label: 'Тип карточки',
            items: VIEW_KIND_SELECT_ITEMS,
            onValueChange: newValue => setViewKind(newValue?.id ?? undefined),
            rawValue: viewKind ?? null,
            theme: SelectThemes.light,
          }}
        />
        <Input
          {...{
            name: 'search',
            className: clsx('ml-a default-control'),
            placeholder: 'Поиск по названию карточки',
            value: search,
            onValueChange: setSearch,
            variant: InputVariants.search,
            theme: InputThemes.light,
          }}
        />
      </div>
    </div>
  );
};
