import React, { useMemo } from 'react';

import clsx from 'clsx';
import R from 'ramda';

import {
  SkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { DateFormats, formatDate } from '~/shared/helpers/date';
import { formatInt, formatNumber } from '~/shared/helpers/number';
import { capitalize } from '~/shared/helpers/string';

import {
  BarChart,
  BarChartDatasetConfig,
  CHART_COLOR_PROPS_ITERATOR,
  DataPointsTooltip,
  DEFAULT_TICKS_COUNT,
  getScaleOptions,
} from '~/features/charts';

import { ColorVariants } from '~/styles/__generated__/token-variants';
import panelStyles from '~/styles/modules/panel.module.scss';

import { LivestockForecastMonthFragment } from '../../gql/fragments/livestockForecastMonth.graphql';
import { getMilkValue } from '../../helpers';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Data for the chart
   */
  livestockForecastMonths: (
    | LivestockForecastMonthFragment
    | SkeletonPlaceholder
  )[];
}

const CHART_HEIGHT_PX = 180;

enum CowsAndMilkChartTypes {
  firstLactationChart = 'firstLactationChart',
  otherLactationsChart = 'otherLactationsChart',
  milkTotalChart = 'milkTotalChart',
}

export const CowsAndMilkChart: React.FC<Props> = ({
  className,
  livestockForecastMonths,
}) => {
  const { getSkeletonClassNames } = useSkeletonContext();

  const { datasets, labels } = useMemo(() => {
    const datasetsInternal = [
      {
        key: CowsAndMilkChartTypes.firstLactationChart,
        label: 'Первотёлки',
        ...CHART_COLOR_PROPS_ITERATOR.next(ColorVariants.status04).value,
        order: 2,
        data: livestockForecastMonths.map(
          m => m.livestockCows?.firstLactation.total ?? 0
        ),
        tooltipConfig: {
          getMainValue: point =>
            getMilkValue(
              livestockForecastMonths[point.parsed.x]?.milk,
              'firstLactation'
            ),
          getCowsCount: point => point.parsed.y,
        },
      },
      {
        key: CowsAndMilkChartTypes.otherLactationsChart,
        label: 'Коровы, лактация ≥ 2',
        ...CHART_COLOR_PROPS_ITERATOR.next().value,
        order: 3,
        data: livestockForecastMonths.map(
          m => m.livestockCows?.otherLactations.total ?? 0
        ),
        tooltipConfig: {
          getMainValue: point =>
            getMilkValue(
              livestockForecastMonths[point.parsed.x]?.milk,
              'otherLactations'
            ),
          getCowsCount: point => point.parsed.y,
        },
      },
      {
        key: CowsAndMilkChartTypes.milkTotalChart,
        label: 'Валовое значение молока',
        ...CHART_COLOR_PROPS_ITERATOR.next().value,
        type: 'line',
        yAxisID: 'milkTotal',
        order: 1,
        data: livestockForecastMonths.map(
          m => getMilkValue(m?.milk, 'total') ?? 0
        ),
        tooltipConfig: {
          getMainValue: point => point.parsed.y,
        },
      },
    ] satisfies BarChartDatasetConfig[];

    return {
      datasets: datasetsInternal,
      labels: livestockForecastMonths.map(month =>
        formatDate(month?.forecastAt, DateFormats.onlyMonthShort)
      ),
    };
  }, [livestockForecastMonths]);

  return (
    <div className={clsx('p-24', panelStyles.largePanel, className)}>
      <Typography
        {...{
          variant: TypographyVariants.bodyLargeStrong,
          isStaticContent: true,
        }}
      >
        Коровы и молоко
      </Typography>

      <BarChart
        {...{
          legendClassName: clsx(
            'mb-24',
            getSkeletonClassNames('mt-16', 'mt-24')
          ),
          isStackedX: true,
          height: CHART_HEIGHT_PX,
          datasets,
          labels,
          renderTooltip: dataPoints => {
            const sortedPoints = R.sortBy(R.prop('datasetIndex'), dataPoints);

            const monthIndex = sortedPoints.at(0)?.parsed.x;
            if (R.isNil(monthIndex)) {
              return null;
            }
            const currentMonth = livestockForecastMonths.at(monthIndex);
            return (
              <DataPointsTooltip
                {...{
                  title: capitalize(
                    formatDate(
                      currentMonth?.forecastAt,
                      DateFormats.monthAndYear
                    )
                  ),
                  dataPoints: sortedPoints,
                  datasets,
                  mainValueMeasurementUnit: 'т',
                  formatMainValue: formatNumber,
                }}
              />
            );
          },
          chartOptions: {
            interaction: {
              mode: 'index',
            },
            scales: {
              y: {
                stacked: true,
                title: {
                  display: true,
                  text: 'Коровы',
                },
                ticks: {
                  count: DEFAULT_TICKS_COUNT,
                },
              },
              milkTotal: getScaleOptions({
                position: 'right',
                title: {
                  display: true,
                  text: 'Молоко, т',
                },
                ticks: {
                  count: DEFAULT_TICKS_COUNT,
                  callback: value => formatInt(value),
                },
              }),
            },
          },
        }}
      />
    </div>
  );
};
