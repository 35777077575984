import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { EventFragmentDoc } from '../fragments/event.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kinds?: Types.InputMaybe<Array<Types.EventKindEnum> | Types.EventKindEnum>;
  ids?: Types.InputMaybe<Array<Types.EventIdInput> | Types.EventIdInput>;
  cowIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  withDefault?: Types.Scalars['Boolean']['input'];
}>;


export type EventsQuery = { __typename?: 'Query', events: { __typename?: 'EventInterfaceConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'EventInterfaceEdge', cursor: string, node: { __typename: 'Injection', name: string, kind: Types.EventKindEnum, id: string, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean } | { __typename: 'Disease', name: string, kind: Types.EventKindEnum, id: string, protocols: Array<{ __typename: 'Protocol', id: string }> } | { __typename: 'DefaultEvent', name: string, kind: Types.EventKindEnum, shortcode: Types.DefaultEventEnum, description: string } | { __typename: 'UserEvent', name: string, kind: Types.EventKindEnum, id: string } | { __typename: 'InseminationScheme', name: string, kind: Types.EventKindEnum, id: string, code: string, description: string } | { __typename: 'Protocol', name: string, kind: Types.EventKindEnum, id: string, expectedDaysOnProtocol?: number | null, daysUntilCheckAfterLastThreatment?: number | null, daysMeatWithhold: number, daysMilkWithhold: number, totalTreatmentCost?: number | null, penGroup?: { __typename: 'PenGroup', id: string } | null, protocolInjections: Array<{ __typename: 'ProtocolInjection', id: string, comment: string, dayNumber: number, injection?: { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean } | null }> } }> } };


export const EventsDocument = gql`
    query events($search: String, $first: Int, $after: String, $kinds: [EventKindEnum!], $ids: [EventIDInput!], $cowIDs: [ID!], $withDefault: Boolean! = true) {
  events(
    search: $search
    first: $first
    after: $after
    kinds: $kinds
    ids: $ids
    cowIDs: $cowIDs
    withDefault: $withDefault
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...Event
      }
      cursor
    }
  }
}
    ${EventFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      kinds: // value for 'kinds'
 *      ids: // value for 'ids'
 *      cowIDs: // value for 'cowIDs'
 *      withDefault: // value for 'withDefault'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export function useEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsSuspenseQueryHookResult = ReturnType<typeof useEventsSuspenseQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;