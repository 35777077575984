/**
 * Do not edit directly
 * Generated on Mon, 11 Nov 2024 09:06:32 GMT
 */

import { useMediaQuery } from '~/shared/hooks/useMediaQuery';

export const useBreakpointSmall320 = () => useMediaQuery('(min-width: 320px) and (max-width: 767px)');

export const useBreakpointSmall320Up = () => useMediaQuery('(min-width: 320px)');

export const useBreakpointMedium768 = () => useMediaQuery('(min-width: 768px) and (max-width: 1279px)');

export const useBreakpointMedium768Up = () => useMediaQuery('(min-width: 768px)');

export const useBreakpointLarge1280 = () => useMediaQuery('(min-width: 1280px) and (max-width: 1919px)');

export const useBreakpointLarge1280Up = () => useMediaQuery('(min-width: 1280px)');

export const useBreakpointXLarge1920Up = () => useMediaQuery('(min-width: 1920px)');
