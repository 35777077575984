import React, { useMemo } from 'react';

import clsx from 'clsx';
import R from 'ramda';

import {
  SkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { DateFormats, formatDate } from '~/shared/helpers/date';
import { formatInt } from '~/shared/helpers/number';
import { capitalize } from '~/shared/helpers/string';

import {
  BarChart,
  BarChartDatasetConfig,
  CHART_COLOR_PROPS_ITERATOR,
  DataPointsTooltip,
  DEFAULT_TICKS_COUNT,
  getScaleOptions,
} from '~/features/charts';

import panelStyles from '~/styles/modules/panel.module.scss';

import { LivestockForecastMonthFragment } from '../../gql/fragments/livestockForecastMonth.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Data for the chart
   */
  livestockForecastMonths: (
    | LivestockForecastMonthFragment
    | SkeletonPlaceholder
  )[];
}

const CHART_HEIGHT_PX = 180;

export const CalvingsAndHeadsChart: React.FC<Props> = ({
  className,
  livestockForecastMonths,
}) => {
  const { getSkeletonClassNames } = useSkeletonContext();

  const { datasets, maxNewbornAnimals, labels } = useMemo(() => {
    const newbornHeifersCounts = livestockForecastMonths.map(
      m => m.calvings?.newbornHeifers ?? 0
    );
    const newbornBullsCounts = livestockForecastMonths.map(
      m => m.calvings?.newbornBulls ?? 0
    );

    const datasetsInternal = [
      {
        key: 'heifersCalvingsChart',
        label: 'Отёлы нетелей',
        ...CHART_COLOR_PROPS_ITERATOR.next(true).value,
        order: 3,
        data: livestockForecastMonths.map(m => m.calvings?.heifers ?? 0),
        tooltipConfig: {
          getMainValue: point => point.parsed.y,
        },
      },
      {
        key: 'cowsCalvingsChart',
        label: 'Отёлы коров',
        ...CHART_COLOR_PROPS_ITERATOR.next().value,
        order: 4,
        data: livestockForecastMonths.map(
          m =>
            (m.calvings?.firstLactation ?? 0) +
            (m.calvings?.otherLactations ?? 0)
        ),
        tooltipConfig: {
          getMainValue: point => point.parsed.y,
        },
      },
      {
        key: 'expectedHeifersChart',
        label: 'Ожидается тёлок',
        ...CHART_COLOR_PROPS_ITERATOR.next().value,
        type: 'line',
        yAxisID: 'heads',
        order: 1,
        isTogglable: true,
        data: newbornHeifersCounts,
        tooltipConfig: {
          getMainValue: point => point.parsed.y,
        },
      },
      {
        key: 'expectedBullsChart',
        label: 'Ожидается быков',
        ...CHART_COLOR_PROPS_ITERATOR.next().value,
        type: 'line',
        yAxisID: 'heads',
        order: 2,
        isTogglable: true,
        data: newbornBullsCounts,
        tooltipConfig: {
          getMainValue: point => point.parsed.y,
        },
      },
    ] satisfies BarChartDatasetConfig[];

    return {
      datasets: datasetsInternal,
      maxNewbornAnimals:
        Math.ceil(
          Math.max(...newbornHeifersCounts, ...newbornBullsCounts) / 10
        ) * 10,
      labels: livestockForecastMonths.map(month =>
        formatDate(month?.forecastAt, DateFormats.onlyMonthShort)
      ),
    };
  }, [livestockForecastMonths]);

  return (
    <div className={clsx('p-24', panelStyles.largePanel, className)}>
      <Typography
        {...{
          variant: TypographyVariants.bodyLargeStrong,
          isStaticContent: true,
        }}
      >
        Отёлы и головы
      </Typography>

      <BarChart
        {...{
          height: CHART_HEIGHT_PX,
          legendClassName: clsx(
            'mb-24',
            getSkeletonClassNames('mt-16', 'mt-24')
          ),
          isStackedX: true,
          datasets,
          labels,
          renderTooltip: dataPoints => {
            const sortedPoints = R.sortBy(R.prop('datasetIndex'), dataPoints);

            const monthIndex = sortedPoints.at(0)?.parsed.x;
            if (R.isNil(monthIndex)) {
              return null;
            }
            return (
              <DataPointsTooltip
                {...{
                  title: capitalize(
                    formatDate(
                      livestockForecastMonths[monthIndex]?.forecastAt,
                      DateFormats.monthAndYear
                    )
                  ),
                  dataPoints: sortedPoints,
                  datasets,
                  formatMainValue: formatInt,
                }}
              />
            );
          },
          chartOptions: {
            interaction: {
              mode: 'index',
            },
            scales: {
              y: {
                stacked: true,
                title: {
                  display: true,
                  text: 'Отёлы',
                },
                ticks: {
                  count: DEFAULT_TICKS_COUNT,
                },
              },
              heads: getScaleOptions({
                min: 0,
                max: maxNewbornAnimals,
                position: 'right',
                title: {
                  display: true,
                  text: 'Головы',
                },
                ticks: {
                  count: DEFAULT_TICKS_COUNT,
                  callback: value => formatInt(value),
                },
              }),
            },
          },
        }}
      />
    </div>
  );
};
