import React, { useEffect } from 'react';

import R from 'ramda';
import { ArrayTail, Join, SnakeCase, Split } from 'type-fest';
import * as yup from 'yup';

import { Input, InputProps, InputVariants } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatInt } from '~/shared/helpers/number';
import { CapitalizeDeep, ValueOf } from '~/shared/types/utility';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import { useFarmsFilter } from '~/entities/farms';

import formStyles from '~/styles/modules/form.module.scss';

import { useSetReproductionTargetsMutation } from '../../gql/mutations/setReproductionTargets.graphql';
import { updateFarmWithReproductionSettingsFragment } from '../../helpers';
import { useFarmWithReproductionSettingsFromCacheOrQuery } from '../../hooks';

export interface EditReproductionTargetsModalProps
  extends InjectedModalProps<EditReproductionTargetsModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
}

const DEVIATION_THRESHOLD_TOOLTIP =
  'Факт будет жёлтого цвета — при значении больше указанной границы, красного цвета — при указанном значении и меньше';

const FORM_ID = 'EditReproductionTargetsForm';

const TARGET_FIELD_NAMES = [
  'hdrCowsTargetPercent',
  'hdrCowsDeviationThresholdPercent',
  'hdrHeifersTargetPercent',
  'hdrHeifersDeviationThresholdPercent',
  'prCowsTargetPercent',
  'prCowsDeviationThresholdPercent',
  'prHeifersTargetPercent',
  'prHeifersDeviationThresholdPercent',
  'crCowsTargetPercent',
  'crCowsDeviationThresholdPercent',
  'crHeifersTargetPercent',
  'crHeifersDeviationThresholdPercent',
] as const;

type TargetFieldNames = ValueOf<typeof TARGET_FIELD_NAMES>;

type TargetFieldNamesSplitted = Split<SnakeCase<TargetFieldNames>, '_'>;
const targetFieldNamesSplitted = TARGET_FIELD_NAMES.map(name =>
  name.replace(/([a-z])([A-Z])/g, '$1_$2').split('_')
) as TargetFieldNamesSplitted[];

type TargetSectionNames = TargetFieldNamesSplitted[0];
type GeneralTargetFieldNames = Join<
  CapitalizeDeep<ArrayTail<TargetFieldNamesSplitted>>,
  ''
>;

const generalTargetFieldNames = R.uniq(
  targetFieldNamesSplitted.map(splittedName => R.tail(splittedName).join(''))
) as GeneralTargetFieldNames[];

const GENERAL_TARGET_FIELD_LABELS: Record<GeneralTargetFieldNames, string> = {
  CowsTargetPercent: 'Цель для коров, %',
  CowsDeviationThresholdPercent: 'Граница допуст. откл. у коров, %',
  HeifersTargetPercent: 'Цель для тёлок, %',
  HeifersDeviationThresholdPercent: 'Граница допуст. откл. у тёлок, %',
};

const TARGET_FIELD_DEFAULTS: Record<TargetFieldNames, number> = {
  hdrCowsTargetPercent: 65,
  hdrCowsDeviationThresholdPercent: 45,
  hdrHeifersTargetPercent: 75,
  hdrHeifersDeviationThresholdPercent: 50,
  prCowsTargetPercent: 25,
  prCowsDeviationThresholdPercent: 15,
  prHeifersTargetPercent: 35,
  prHeifersDeviationThresholdPercent: 20,
  crCowsTargetPercent: 45,
  crCowsDeviationThresholdPercent: 33,
  crHeifersTargetPercent: 60,
  crHeifersDeviationThresholdPercent: 40,
};

const SCHEMA = yup.object(
  Object.fromEntries(
    TARGET_FIELD_NAMES.map(name => [
      name,
      yup.number().default(TARGET_FIELD_DEFAULTS[name]).required().positive(),
    ])
  )
) as yup.ObjectSchema<Record<TargetFieldNames, number>>;

type EditReproductionTargetsFormType = InferValidatedSchema<typeof SCHEMA>;

export const EditReproductionTargetsModal: React.FC<
  EditReproductionTargetsModalProps
> = ({ className, close }) => {
  const { sendSuccessToast } = useNotifications();

  const { farmId, renderFarmsSelectElement } = useFarmsFilter(false);

  const {
    fragment: farmFragment,
    fragmentPromise: farmFragmentPromise,
    isLoading,
  } = useFarmWithReproductionSettingsFromCacheOrQuery(farmId);

  const formContext = useForm<EditReproductionTargetsFormType>({
    schema: SCHEMA,
    defaultValues: () =>
      farmFragmentPromise.then(fragment => ({
        ...SCHEMA.getDefault(),
        ...fragment?.reproductionTargets,
      })),
  });

  const [setReproductionTargets] = useSetReproductionTargetsMutation();

  const handleSubmit = (input: EditReproductionTargetsFormType) => {
    if (!farmId) return;

    setReproductionTargets({
      variables: {
        farmID: farmId,
        input,
      },
      optimisticResponse: { setReproductionTargets: null },
      update: updateFarmWithReproductionSettingsFragment(farmId, draft => {
        draft.reproductionTargets.hdrCowsTargetPercent =
          input.hdrCowsTargetPercent;
        draft.reproductionTargets.hdrCowsDeviationThresholdPercent =
          input.hdrCowsDeviationThresholdPercent;
        draft.reproductionTargets.hdrHeifersTargetPercent =
          input.hdrHeifersTargetPercent;
        draft.reproductionTargets.hdrHeifersDeviationThresholdPercent =
          input.hdrHeifersDeviationThresholdPercent;
        draft.reproductionTargets.prCowsTargetPercent =
          input.prCowsTargetPercent;
        draft.reproductionTargets.prCowsDeviationThresholdPercent =
          input.prCowsDeviationThresholdPercent;
        draft.reproductionTargets.prHeifersTargetPercent =
          input.prHeifersTargetPercent;
        draft.reproductionTargets.prHeifersDeviationThresholdPercent =
          input.prHeifersDeviationThresholdPercent;
        draft.reproductionTargets.crCowsTargetPercent =
          input.crCowsTargetPercent;
        draft.reproductionTargets.crCowsDeviationThresholdPercent =
          input.crCowsDeviationThresholdPercent;
        draft.reproductionTargets.crHeifersTargetPercent =
          input.crHeifersTargetPercent;
        draft.reproductionTargets.crHeifersDeviationThresholdPercent =
          input.crHeifersDeviationThresholdPercent;
      }),
      refetchQueries: ['reproductionMainChart'],
    }).then(() => {
      sendSuccessToast('Настройки целей сохранены');
    });

    close();
  };

  const formValues = formContext.watch();

  // Reset form with new settings, when we select different farm
  useEffect(() => {
    if (!farmFragment) return;

    formContext.reset(farmFragment.reproductionTargets);
  }, [farmFragment]);

  const renderInput = (inputProps: InputProps & { name: TargetFieldNames }) => {
    const inputValue = formValues[inputProps.name];
    const defaultValue = TARGET_FIELD_DEFAULTS[inputProps.name];

    const feedback =
      !inputValue || inputValue === defaultValue
        ? undefined
        : `Значение по умолчанию: ${formatInt(defaultValue)}%`;

    return (
      <Input
        {...{
          ...inputProps,
          feedback,
        }}
        key={inputProps.key}
      />
    );
  };

  const renderTargetSection = (
    sectionName: TargetSectionNames,
    title: string
  ) => (
    <div>
      <Typography
        className="mb-12"
        tag="h3"
        variant={TypographyVariants.bodyMediumStrong}
      >
        {title}
      </Typography>
      <div className={formStyles.twoColumnForm}>
        {generalTargetFieldNames.map((fieldName, fieldIndex) =>
          renderInput({
            key: fieldName,
            name: `${sectionName}${fieldName}`,
            label: GENERAL_TARGET_FIELD_LABELS[fieldName],
            variant: InputVariants.int,
            isDisabled: isLoading,
            labelProps: {
              tooltip: fieldIndex % 2 ? DEVIATION_THRESHOLD_TOOLTIP : undefined,
            },
          })
        )}
      </div>
    </div>
  );

  return (
    <Modal
      {...{
        className,
        title: 'Настройки ПДО для страницы «Общее»',
        submitButtonProps: {
          form: FORM_ID,
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          className: 'flex flex-col gap-24',
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        {renderFarmsSelectElement({
          name: 'modalFarmId',
          className: 'full-width',
          theme: SelectThemes.dark,
          withFormContext: false,
        })}
        {renderTargetSection('hdr', 'Выявление (HDR)')}
        {renderTargetSection('pr', 'Стельность (PR)')}
        {renderTargetSection('cr', 'Плодотворные осеменения (CR)')}
      </Form>
    </Modal>
  );
};
