import { Calving, CowState } from '@graphql-types';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';

import {
  AppBull,
  AppCow,
  AppInsemination,
  AppInseminationScheme,
  PenGroupShortInfo,
  UserFarm,
} from '~/~legacy/types/entities';
import { SpecialConstants } from '~/~legacy/types/specialConstants';

import { ValueKindEnum } from '~/shared/graphql';
import { formatDate } from '~/shared/helpers/date';
import { PartialRecord } from '~/shared/types/utility';

import { getNumberPartFromGlobalId } from '~/services/gql';

import { COW_STATES_DICT, formatCow } from '~/entities/cows';

import { ValueEditorKind } from '~/widgets/blueprintEdit';

// TODO: Refactor work with values,
// make separate renders by value type (maybe leave them in ~legacy/value-editors)
// and import them here to assemble for common usage

type ValueKindGetter = (nonNormalizeValue: any, t?: TFunction) => any;

export const defaultEditGetter: ValueKindGetter = val => val;
export const defaultRenderGetter: ValueKindGetter = val => JSON.stringify(val);

const commonKinds: PartialRecord<ValueEditorKind, ValueKindGetter> = {
  [ValueKindEnum.Date]: val => val?.dateValue || val?.datetimeValue,
  [ValueKindEnum.Int]: val => {
    return val?.intValue;
  },
  [ValueKindEnum.Float]: val => val?.floatValue,
  [ValueKindEnum.Numeric]: val => val?.floatValue,
  [ValueKindEnum.Text]: val => val?.strValue,
  [ValueKindEnum.Index]: val => val?.intValue,
  [ValueKindEnum.LactationIndex]: val => val?.intValue,
  [ValueKindEnum.Dow]: val => val?.strValue,
  [ValueKindEnum.CowState]: val => val?.strValue,
  [ValueKindEnum.CowRetirementReason]: val => val?.strValue,
  [ValueKindEnum.LactationState]: val => val?.strValue,
  [ValueKindEnum.FarmId]: (val: UserFarm) =>
    val ? `${val?.id} / ${val?.name}` : val,
};

export const editKindsMap: PartialRecord<ValueEditorKind, ValueKindGetter> = {
  ...commonKinds,

  [ValueKindEnum.Void]: () => undefined,
  [ValueKindEnum.Bool]: val => Boolean(val?.intValue),

  [SpecialConstants.Unknown]: defaultEditGetter,
};

export const renderKindsMap: PartialRecord<ValueEditorKind, ValueKindGetter> = {
  ...commonKinds,
  [ValueKindEnum.CowState]: val => {
    const value = val?.strValue;
    return COW_STATES_DICT[value as CowState] ?? value;
  },

  [ValueKindEnum.Date]: val =>
    val && (val.dateValue || val.datetimeValue)
      ? formatDate(dayjs(val.dateValue || val.datetimeValue))
      : `${val || ''}`,
  [ValueKindEnum.Bool]: val =>
    (typeof val === 'object' && val?.intValue) ||
    (typeof val === 'boolean' && val)
      ? 'Да'
      : 'Нет',
  [ValueKindEnum.PenGroupId]: (val: PenGroupShortInfo) =>
    val ? `Группа № ${val?.identifier || val.id} ${val?.name || ''}` : val,
  [ValueKindEnum.EmployeeId]: val =>
    val ? `${val?.firstName} ${val?.lastName}` : val,
  [ValueKindEnum.EventId]: val => val?.strValue || val?.shortcode || val?.name,
  [ValueKindEnum.InjectionId]: val => val?.name,
  [ValueKindEnum.ProtocolId]: val => val?.name,
  [ValueKindEnum.DiseaseId]: val => val?.name,
  [ValueKindEnum.InseminationSchemeId]: (val: AppInseminationScheme) =>
    val?.name,
  [ValueKindEnum.InseminationId]: (val: AppInsemination) =>
    val
      ? `[${val.id}] ${val.happenedAt} [${val.cow.id}] [${val.bull.id}]`
      : undefined,
  // [ValueKindEnum.SemenDoseId]: (val: AppSemenDose) =>
  //   `[${val?.deliveryDate}] ${val?.bullName}`,
  [ValueKindEnum.BullId]: (val: AppBull) =>
    val ? `Бык №${val.registrationNumber}` : val,
  [ValueKindEnum.CowId]: (val: AppCow) =>
    val ? `[${val?.identifier}] ${val?.name}` : '',

  [ValueKindEnum.CalvingId]: (val: Calving | null) => {
    if (!val) return null;
    const id = getNumberPartFromGlobalId(val.id);
    const cowNumber = formatCow(val.cow, { withName: false });
    const formattedDate = formatDate(val.happenedAt);

    return `№ ${id}; ${cowNumber}; ${formattedDate}`;
  },
  [ValueKindEnum.Void]: defaultRenderGetter,
  [SpecialConstants.Unknown]: defaultRenderGetter,
};
