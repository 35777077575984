import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintRoleFragmentDoc } from '../../../../services/auth/gql/fragments/blueprintRole.graphql';
export type CustomMilkingReportFragment = { __typename: 'CustomMilkingReport', id: string, name: string, blueprintRoles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> };

export const CustomMilkingReportFragmentDoc = gql`
    fragment CustomMilkingReport on CustomMilkingReport {
  __typename
  id
  name
  blueprintRoles {
    ...BlueprintRole
  }
}
    ${BlueprintRoleFragmentDoc}`;