import {
  ConceptionRateParameterEnum,
  FemaleAnimalKind,
  ReproductionRowsXAxisStep,
  ReproductionYAxisMode,
} from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import dayjs from 'dayjs';

import {
  Button,
  ButtonSizes,
  ButtonVariants,
} from '~/shared/components/Button';
import {
  DateRangePicker,
  DateRangePickerThemes,
} from '~/shared/components/DateRangePicker';
import { IconVariants } from '~/shared/components/Icon/__generated__/iconVariants';
import { SelectThemes } from '~/shared/components/Select';
import {
  getSingleSkeletonPlaceholder,
  Skeleton,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatDateForBackend } from '~/shared/helpers/date';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import {
  useLayoutState,
  WithSearchParamsValidation,
} from '~/services/navigation';

import { FarmFilterSearchParams, useFarmsFilter } from '~/entities/farms';

import {
  FemaleAnimalKindSelect,
  ReproductionConceptionRateParameterSelect,
  ReproductionCrChart,
  ReproductionHdrAndPrChart,
  ReproductionMetricCard,
  ReproductionRowsXAxisStepSelect,
  ReproductionYAxisModeSelect,
  useEditReproductionSettingsModal,
  useEditReproductionTargetsModal,
} from '~/widgets/analyticsReproduction';
import { useReproductionMainChartQuery } from '~/widgets/analyticsReproduction/gql/queries/reproductionMainChart.graphql';
import { useReproductionMainCrChartQuery } from '~/widgets/analyticsReproduction/gql/queries/reproductionMainCrChart.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import styles from './overview.module.scss';

interface ReproductionOverviewSearchParams extends FarmFilterSearchParams {
  since: string;
  till: string;
  femaleAnimalKind: FemaleAnimalKind;
  mainChartYAxisMode: ReproductionYAxisMode;
  crChartYAxisMode: ReproductionYAxisMode;
  crChartXAxisStep: ReproductionRowsXAxisStep;
  crGroupBy: ConceptionRateParameterEnum;
}

const DEFAULT_PERIOD_SINCE = formatDateForBackend(
  dayjs().subtract(6, 'months'),
  true
);
const DEFAULT_PERIOD_TILL = formatDateForBackend(dayjs(), true);

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/reproduction/overview'
)({
  wrapInSuspense: true,
  component: ReproductionOverviewPage,
  validateSearch: ({
    farmId,
    since,
    till,
    femaleAnimalKind,

    mainChartYAxisMode,
    crChartYAxisMode,
    crChartXAxisStep,

    crGroupBy,
  }: WithSearchParamsValidation<ReproductionOverviewSearchParams>) => ({
    farmId: farmId ?? null,
    since: since || DEFAULT_PERIOD_SINCE,
    till: till || DEFAULT_PERIOD_TILL,
    femaleAnimalKind: femaleAnimalKind ?? FemaleAnimalKind.Cow,

    mainChartYAxisMode: mainChartYAxisMode ?? ReproductionYAxisMode.Percent_100,

    crChartYAxisMode: crChartYAxisMode ?? ReproductionYAxisMode.Percent_100,
    crChartXAxisStep: crChartXAxisStep ?? ReproductionRowsXAxisStep.Day,
    crGroupBy: crGroupBy ?? ConceptionRateParameterEnum.Bull,
  }),
});

function ReproductionOverviewPage() {
  const { farmId, renderFarmsSelectElement } = useFarmsFilter(false);
  const {
    since,
    setSince,
    till,
    setTill,
    femaleAnimalKind,
    setFemaleAnimalKind,

    mainChartYAxisMode,
    setMainChartYAxisMode,

    crChartYAxisMode,
    setCrChartYAxisMode,
    crGroupBy,
    setCrGroupBy,
    crChartXAxisStep,
    setCrChartXAxisStep,
  } = useSearchParamsState<ReproductionOverviewSearchParams>();
  const selectedPeriod = { since, till };

  const { open: openEditReproductionSettingsModal } =
    useEditReproductionSettingsModal();

  const { open: openEditReproductionTargetsModal } =
    useEditReproductionTargetsModal();

  useLayoutState({
    rightContent: (
      <Button
        {...{
          variant: ButtonVariants.secondary,
          iconVariant: IconVariants.settings,
          children: 'Настройки ПДО',
          onPress: () => openEditReproductionSettingsModal(),
        }}
      />
    ),
  });

  const commonChartQueryVariables = {
    farmID: farmId ?? '',
    since,
    till,
    femaleAnimalKind,
  };

  const {
    data: reproductionMainChartQueryData,
    loading: isReproductionMainChartLoading,
  } = useReproductionMainChartQuery({
    variables: commonChartQueryVariables,
    skip: !farmId,
  });

  const reproductionMainChartData =
    reproductionMainChartQueryData?.reproductionMainChart ??
    getSingleSkeletonPlaceholder();

  const {
    data: reproductionMainCrChartQueryData,
    loading: isReproductionMainCrChartLoading,
  } = useReproductionMainCrChartQuery({
    variables: {
      ...commonChartQueryVariables,
      groupBy: crGroupBy,
      xAxisStep: crChartXAxisStep,
    },
    skip: !farmId,
  });

  const reproductionMainCrChartData =
    reproductionMainCrChartQueryData?.reproductionMainCrChart ??
    getSingleSkeletonPlaceholder();

  return (
    <>
      <div
        className={clsx(
          layoutStyles.limitedContainer,
          'flex gap-16 items-center mb-24'
        )}
      >
        {renderFarmsSelectElement({
          className: 'default-control',
          rawValue: farmId,
        })}
        <DateRangePicker
          {...{
            className: 'default-control',
            label: 'Период отображения',
            name: 'period',
            value: selectedPeriod,
            theme: DateRangePickerThemes.light,
            onValueChange: range => {
              setSince(range.since ?? DEFAULT_PERIOD_SINCE);
              setTill(range.till ?? DEFAULT_PERIOD_TILL);
            },
          }}
        />
        <FemaleAnimalKindSelect
          {...{
            className: 'default-control',
            label: 'Животные',
            name: 'femaleAnimalKind',
            rawValue: femaleAnimalKind,
            theme: SelectThemes.light,
            onValueChange: newKind => {
              setFemaleAnimalKind(newKind?.id as FemaleAnimalKind);
            },
          }}
        />
      </div>
      <div
        className={clsx(
          layoutStyles.limitedContainer,
          panelStyles.largePanel,
          'p-24 grid gap-24 mb-24'
        )}
      >
        <div className="flex justify-between gap-24">
          <Typography tag="h3" variant={TypographyVariants.bodyMediumStrong}>
            График показателей Выявление (HDR), Стельность (PR) и Плодотворные
            осеменения (CR)
          </Typography>
          <Button
            {...{
              variant: ButtonVariants.secondary,
              size: ButtonSizes.small24,
              iconVariant: IconVariants.settings,
              onPress: () => openEditReproductionTargetsModal(),
              shouldHideLabelOnMobile: true,
            }}
          >
            Настройки целей
          </Button>
        </div>
        <ReproductionYAxisModeSelect
          {...{
            className: 'default-control',
            name: 'mainChartYAxisMode',
            label: 'Шкала',
            rawValue: mainChartYAxisMode,
            onValueChange: newValue =>
              setMainChartYAxisMode(newValue?.id as ReproductionYAxisMode),
          }}
        />
        <Skeleton withDelay isLoading={isReproductionMainChartLoading}>
          <div className={styles.chartWithCards}>
            <div className={styles.cards}>
              <ReproductionMetricCard
                {...{
                  title: 'Выявление (HDR)',
                  metric:
                    reproductionMainChartData.hdrMetric ??
                    getSingleSkeletonPlaceholder(),
                  isLastCycleCalculation: true,
                }}
              />
              <ReproductionMetricCard
                {...{
                  title: 'Стельность (PR)',
                  metric:
                    reproductionMainChartData.prMetric ??
                    getSingleSkeletonPlaceholder(),
                }}
              />
              <ReproductionMetricCard
                {...{
                  title: 'Плодотворн. осемен. (CR)',
                  metric:
                    reproductionMainChartData.crMetric ??
                    getSingleSkeletonPlaceholder(),
                }}
              />
            </div>
            <ReproductionHdrAndPrChart
              {...{
                reportRows: reproductionMainChartData.rows ?? [],
                yAxisMode: mainChartYAxisMode,
              }}
            />
          </div>
        </Skeleton>
      </div>

      <div
        className={clsx(
          layoutStyles.limitedContainer,
          panelStyles.largePanel,
          'p-24 grid gap-24'
        )}
      >
        <Typography tag="h3" variant={TypographyVariants.bodyMediumStrong}>
          График показателя Плодотворные осеменения (CR)
        </Typography>
        <div className="flex flex-wrap gap-16">
          <ReproductionConceptionRateParameterSelect
            {...{
              className: 'default-control',
              label: 'Группировка',
              name: 'conceptionRateParameter',
              rawValue: crGroupBy,
              onValueChange: newValue =>
                setCrGroupBy(newValue?.id as ConceptionRateParameterEnum),
            }}
          />
          {crGroupBy === ConceptionRateParameterEnum.Date && (
            <ReproductionRowsXAxisStepSelect
              {...{
                className: 'default-control',
                label: 'Временной шаг',
                name: 'crChartXAxisStep',
                rawValue: crChartXAxisStep,
                onValueChange: newValue =>
                  setCrChartXAxisStep(
                    newValue?.id as ReproductionRowsXAxisStep
                  ),
              }}
            />
          )}
          <ReproductionYAxisModeSelect
            {...{
              className: 'default-control',
              name: 'crChartYAxisMode',
              label: 'Шкала',
              rawValue: crChartYAxisMode,
              onValueChange: newValue =>
                setCrChartYAxisMode(newValue?.id as ReproductionYAxisMode),
            }}
          />
        </div>
        <Skeleton withDelay isLoading={isReproductionMainCrChartLoading}>
          <ReproductionCrChart
            {...{
              chartData: reproductionMainCrChartData,
              yAxisMode: crChartYAxisMode,
              crGroupBy,
            }}
          />
        </Skeleton>
      </div>
    </>
  );
}
