import React, { useEffect, useMemo, useRef, useState } from 'react';

import { SkeletonBarChart } from './components/SkeletonBarChart';
import { SkeletonBlock } from './components/SkeletonBlock';
import { SkeletonLineChart } from './components/SkeletonLineChart';
import { SkeletonText } from './components/SkeletonText';
import { MIN_SKELETON_SHOW_MS, SKELETON_SHOW_DELAY_MS } from './constants';
import {
  SkeletonContext,
  SkeletonContextType,
  useSkeletonContext,
} from './context';

interface Props extends React.PropsWithChildren {
  /**
   * If true, skeleton provides loading state for its children
   */
  isLoading?: boolean;
  /**
   * If true, isLoading true state is applied with delay and min show time
   */
  withDelay?: boolean;
}

export const Skeleton = ({
  isLoading: isLoadingProp = false,
  children,
  withDelay = false,
}: Props) => {
  const minSkeletonShowDelayTimeoutRef = useRef<NodeJS.Timeout>();
  const skeletonMinShowPromiseRef = useRef<Promise<void>>();

  const { isLoading: isLoadingContext } = useSkeletonContext();

  const [isLoadingState, setIsLoadingState] = useState(isLoadingProp);

  useEffect(() => {
    if (!withDelay) {
      return () => {};
    }

    if (isLoadingProp) {
      minSkeletonShowDelayTimeoutRef.current = setTimeout(() => {
        setIsLoadingState(isLoadingProp);

        // Don't allow to set isLoading for a min show time to avoid flashing
        skeletonMinShowPromiseRef.current = new Promise(resolve =>
          setTimeout(() => {
            resolve();
            skeletonMinShowPromiseRef.current = undefined;
          }, MIN_SKELETON_SHOW_MS)
        );
      }, SKELETON_SHOW_DELAY_MS);
    } else {
      (skeletonMinShowPromiseRef.current ?? Promise.resolve()).then(() => {
        setIsLoadingState(isLoadingProp);
      });
    }

    return () => {
      clearTimeout(minSkeletonShowDelayTimeoutRef.current);
    };
  }, [isLoadingProp]);

  // Sometimes we may have a case of rendering nested Skeletons,
  // but we shouldn't end up resetting initial loading state
  const isLoading =
    isLoadingContext || (withDelay ? isLoadingState : isLoadingProp);

  const contextValue = useMemo<SkeletonContextType>(
    () => ({
      isLoading,
      renderWithSkeleton: (skeleton, content, isStaticContent = false) =>
        isLoading && !isStaticContent ? skeleton : content,
      getSkeletonClassNames: (skeletonClassNames, contentClassNames = '') =>
        isLoading ? skeletonClassNames : contentClassNames,
      renderWithoutSkeleton: content => (isLoading ? null : content),
    }),
    [isLoading, isLoadingContext]
  );

  return (
    <SkeletonContext.Provider value={contextValue}>
      {children}
    </SkeletonContext.Provider>
  );
};

Skeleton.Block = SkeletonBlock;
Skeleton.Text = SkeletonText;
Skeleton.BarChart = SkeletonBarChart;
Skeleton.LineChart = SkeletonLineChart;

export * from './context';
export * from './helpers';
export * from './types';
export * from './hooks';
export { TextSkeletonSizes } from './components/SkeletonText';
