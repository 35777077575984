import R from 'ramda';

import { capitalize } from '~/shared/helpers/string';

import { ColorVariants } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';

/**
 * Each color has this basic tints, but using ColorShades is more preferable
 */
type ColorTints =
  | 0
  | 10
  | 30
  | 50
  | 75
  | 100
  | 150
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 925
  | 950
  | 990
  | 1000;

/**
 * Each color variant has the same shades, so we can get its name by mixing the variant and the shade
 * @public -- this is a design system contract, so we ignore some unused members for knip
 */
export enum ColorShades {
  default = 'default',
  soft = 'soft',
  muted = 'muted',
  hover = 'hover',
  active = 'active',
  containerDefault = 'containerDefault',
  containerSoft = 'containerSoft',
  containerMuted = 'containerMuted',
  containerHover = 'containerHover',
  containerActive = 'containerActive',
  opaqueContainerDefault = 'opaqueContainerDefault',
  opaqueContainerSoft = 'opaqueContainerSoft',
  opaqueContainerMuted = 'opaqueContainerMuted',
  opaqueContainerHover = 'opaqueContainerHover',
  opaqueContainerActive = 'opaqueContainerActive',
}

/**
 * Gets a color value of the design system color by passed variant
 * and tint (number) or shade (pre-defined common use cases)
 */
export const getColorTokenValue = (
  variant: ColorVariants,
  shadeOrTint: ColorShades | ColorTints
) => {
  // Color variants with number at the end has a '_' delimiter when used with number tints
  const shadeDelimiter =
    typeof shadeOrTint === 'number' && variant.match(/\d$/) ? '_' : '';
  return TOKENS[
    `color${capitalize(variant)}${shadeDelimiter}${capitalize(shadeOrTint.toString())}` as keyof typeof TOKENS
  ] as string;
};

/**
 * Creates an iterator, that yields all possible combinations of passed variants and shades.
 * First it iterates through variants and yields each possible variant with the first shade, then with the second etc.
 * If you pass true into the .next() call, iterator will start from the beginning.
 * You can also pass a color variant from variants array to set iterator to iter from the passed color.
 */
export const makeColorIterator = (
  variants: ColorVariants[],
  shadeOrTints: (ColorShades | ColorTints)[]
) => {
  const totalCombinations = variants.length * shadeOrTints.length;

  const generator = function* () {
    let currentColorIndex = 0;

    while (true) {
      const colorIndex = currentColorIndex % totalCombinations;
      const shadeIndex = Math.floor(colorIndex / variants.length);
      const variantIndex = colorIndex - shadeIndex * variants.length;

      const shouldResetOrNextColor: ColorVariants | boolean | undefined =
        yield getColorTokenValue(
          variants[variantIndex],
          shadeOrTints[shadeIndex]
        );

      currentColorIndex += 1;

      if (typeof shouldResetOrNextColor === 'string') {
        const nextColorIndex = variants.indexOf(shouldResetOrNextColor);
        if (nextColorIndex >= 0) {
          currentColorIndex = nextColorIndex;
        }
      } else if (shouldResetOrNextColor) {
        currentColorIndex = 0;
      }
    }
  };

  return generator();
};

/**
 * Makes an iterator that combines other iterators from the passed props object.
 * When .next() is called, it iterate over all iterators at once
 * and yields a props object with the same keys as in iteratorByPropsDict.
 * .next() argument is passed into makeColorIterator
 */
export const makeColorPropsIterator = <T>(
  iteratorByPropsDict: Record<keyof T, ReturnType<typeof makeColorIterator>>
) => {
  const generator = function* () {
    let shouldResetOrNextColor: ColorVariants | boolean | undefined;
    while (true) {
      const colorProps = R.map(
        (iterator: ReturnType<typeof makeColorIterator>) =>
          iterator.next(shouldResetOrNextColor).value,
        iteratorByPropsDict
      );
      shouldResetOrNextColor = yield colorProps;
    }
  };

  return generator();
};
