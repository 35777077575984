import { MutableRefObject, useRef } from 'react';

import { BlueprintRoleFragment } from '../gql/fragments/blueprintRole.graphql';
import { MyUserQuery, useMyUserQuery } from '../gql/queries/myUser.graphql';

const myUserGlobalRef: MutableRefObject<MyUserQuery['myUser'] | null> = {
  current: null,
};

/**
 * Hook for accessing current user info, that may be not yet in the cache, so it can return undefined
 */
export const useMyUserUnsafe = () => {
  const myUserRef = useRef<MyUserQuery['myUser']>();

  // We preload user in the RequireAuthWrapper, so we only need to get it from cache
  const { data } = useMyUserQuery({ fetchPolicy: 'cache-only' });

  const myUserFromCache = data?.myUser;

  if (myUserFromCache) {
    // Save myUser, so if we reset the cache, it will be still available, while reloading
    myUserRef.current = myUserFromCache;
    myUserGlobalRef.current = myUserFromCache;
  }

  const myUser =
    myUserFromCache ?? myUserRef.current ?? myUserGlobalRef.current;

  const isIntegratorByCompanyId = (companyId: string | undefined) =>
    !!myUser?.companies.edges.some(
      companyEdge =>
        companyEdge.node.id === companyId && companyEdge.canEditSettings
    );

  const getRolesByCompanyId = (
    companyId: string | undefined
  ): BlueprintRoleFragment[] =>
    myUser?.companies.edges.find(
      companyEdge => companyEdge.node.id === companyId
    )?.blueprintRoles ?? [];

  return {
    myUser,
    isIntegrator: !!myUser?.integrator,
    isIntegratorByCompanyId,
    getRolesByCompanyId,
  };
};

/**
 * Hook for accessing current user info, that checks, it is always in the cache
 */
export const useMyUser = () => {
  const { myUser, ...other } = useMyUserUnsafe();

  if (!myUser) {
    throw new Error(
      'myUser is not found in the cache! useMyUser should only be called inside the RequireAuthWrapper'
    );
  }

  return {
    myUser,
    ...other,
  };
};
