import { Dow } from '@graphql-types';
import dayjs, { ConfigType as AnyDateType } from 'dayjs';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { DEFAULT_LOCALE } from '~/shared/constants';

export type { ConfigType as AnyDateType } from 'dayjs';

/**
 * Simple container for a duration
 */
export interface DateRange {
  since?: string;
  till?: string;
}

/**
 * Days of week display names
 */
export const DAYS_OF_WEEK_DICT: Record<Dow, string> = {
  [Dow.Mon]: 'Понедельник',
  [Dow.Tue]: 'Вторник',
  [Dow.Wed]: 'Среда',
  [Dow.Thu]: 'Четверг',
  [Dow.Fri]: 'Пятница',
  [Dow.Sat]: 'Суббота',
  [Dow.Sun]: 'Воскресенье',
};

/**
 * Days of week, sorted in the correct order
 */
export const DAYS_OF_WEEK = Object.values(Dow);

const MAX_SHORT_MONTH_LENGTH = 3;

const BACKEND_ONLY_DATE_FORMAT = 'YYYY-MM-DD';
const BACKEND_ONLY_TIME_FORMAT = 'HH:mm:ss';

const YEAR_LITERAL = 'г.';
/**
 * Basic date formats, used across the system
 */
export enum DateFormats {
  short = 'short',
  shortWithWeekDay = 'shortWithWeekDay',
  onlyYear = 'onlyYear',
  onlyMonthShort = 'onlyMonthShort',
  onlyMonth = 'onlyMonth',
  monthAndYear = 'monthAndYear',
  dayAndMonth = 'dayAndMonth',
  dayAndMonthShort = 'dayAndMonthShort',
  dayAndMonthTwoDigit = 'dayAndMonthTwoDigit',
  full = 'full',
  fullWithTime = 'fullWithTime',
  shortWithTime = 'shortWithTime',
  onlyTime = 'onlyTime',
  duration = 'duration',
  durationWithSeconds = 'durationWithSeconds',
}

/**
 * Additional formats for date range, when we format since and till differently
 */
export enum DateRangeFormats {
  monthsAndSingleYear = 'monthsAndSingleYear',
}

type DateFormatOptions = DateFormats | Intl.DateTimeFormatOptions;

/**
 * Basic date formats in the system
 */
const DATE_FORMATS_SETTINGS: Record<DateFormats, Intl.DateTimeFormatOptions> = {
  [DateFormats.short]: {},
  [DateFormats.shortWithWeekDay]: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    weekday: 'short',
  },
  [DateFormats.onlyYear]: {
    year: 'numeric',
  },
  [DateFormats.onlyMonthShort]: {
    month: 'short',
  },
  [DateFormats.onlyMonth]: {
    month: 'long',
  },
  [DateFormats.monthAndYear]: {
    month: 'long',
    year: 'numeric',
  },
  [DateFormats.dayAndMonth]: {
    day: 'numeric',
    month: 'long',
  },
  [DateFormats.dayAndMonthShort]: {
    day: 'numeric',
    month: 'short',
  },
  [DateFormats.dayAndMonthTwoDigit]: {
    day: '2-digit',
    month: '2-digit',
  },
  [DateFormats.full]: {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  },
  [DateFormats.fullWithTime]: {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
  [DateFormats.shortWithTime]: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
  [DateFormats.onlyTime]: {
    hour: '2-digit',
    minute: '2-digit',
  },
  [DateFormats.duration]: {
    hour: 'numeric',
    minute: '2-digit',
  },
  [DateFormats.durationWithSeconds]: {
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  },
};

/**
 * Format settings for date ranges
 */
const DATE_RANGE_FORMAT_SETTINGS: Record<
  DateRangeFormats,
  { since: DateFormats; till: DateFormats }
> = {
  [DateRangeFormats.monthsAndSingleYear]: {
    since: DateFormats.onlyMonth,
    till: DateFormats.monthAndYear,
  },
};

/**
 * Format date to ISO format with time-zone
 * If isOnlyDate is true, than returns only date, without time.
 */
export const formatDateForBackend = (
  date?: AnyDateType,
  isOnlyDate = false
) => {
  if (!date || !dayjs(date).isValid()) return '';

  // return date 'YYYY-MM-DDTHH:mm:ssZZ'
  return dayjs(date).format(isOnlyDate ? BACKEND_ONLY_DATE_FORMAT : undefined);
};

/**
 * Format date to human format
 */
export const formatDate = (
  date?: AnyDateType,
  formatOrOptions: DateFormatOptions = DateFormats.short
) => {
  const parsedDate = dayjs(date);

  if (!dayjs(parsedDate).isValid()) {
    return '';
  }
  let intlOptions = formatOrOptions;
  if (typeof intlOptions === 'string') {
    intlOptions = DATE_FORMATS_SETTINGS[intlOptions];
  }

  const intl = new Intl.DateTimeFormat(DEFAULT_LOCALE, intlOptions);

  let result = intl
    .formatToParts(parsedDate.toDate())
    .map(part =>
      match(part)
        .with(
          { type: 'literal', value: P.select(P.string.includes(YEAR_LITERAL)) },
          matchedLiteral => matchedLiteral.split(YEAR_LITERAL).at(-1) ?? ''
        )
        .otherwise(R.always(part.value))
    )
    .join('');

  // We clamp result for short months, cause default format has different lengths for different months
  if (formatOrOptions === DateFormats.onlyMonthShort) {
    result = result.slice(0, MAX_SHORT_MONTH_LENGTH);
  }

  return result;
};

/**
 * Used to format time strings without date component, cause they need special parsing
 */
export const formatTime = (
  time?: string,
  formatOrOptions: DateFormatOptions = DateFormats.onlyTime
) => {
  return formatDate(dayjs(time, BACKEND_ONLY_TIME_FORMAT), formatOrOptions);
};

/**
 * Delimiter, used to format date ranges
 */
const DATE_RANGE_DELIMITER = '–';

/**
 * Regexp to split date range to make multiline array labels for chart.js
 */
export const DATE_RANGE_REGEXP = new RegExp(`(?<=${DATE_RANGE_DELIMITER}) `);

/**
 * Format date range to human format
 */
export const formatDateRange = (
  since: AnyDateType,
  till: AnyDateType,
  formatOrOptions: DateRangeFormats | DateFormatOptions = DateFormats.short
) => {
  let formatSinceOptions: DateFormatOptions;
  let formatTillOptions: DateFormatOptions;
  if (
    Object.values(DateRangeFormats).includes(
      formatOrOptions as DateRangeFormats
    )
  ) {
    formatSinceOptions =
      DATE_RANGE_FORMAT_SETTINGS[formatOrOptions as DateRangeFormats].since;
    formatTillOptions =
      DATE_RANGE_FORMAT_SETTINGS[formatOrOptions as DateRangeFormats].till;
  } else {
    formatSinceOptions = formatOrOptions as DateFormatOptions;
    formatTillOptions = formatOrOptions as DateFormatOptions;
  }

  const formattedSince = formatDate(since, formatSinceOptions);
  const formattedTill = formatDate(till, formatTillOptions);
  if (formattedSince === formattedTill) {
    return formattedSince;
  }
  return `${formattedSince} ${DATE_RANGE_DELIMITER} ${formattedTill}`;
};

/**
 * Format time range to human format
 */
export const formatTimeRange = (
  since?: string,
  till?: string,
  formatOrOptions: DateFormatOptions = DateFormats.onlyTime
) => {
  const formattedSince = formatTime(since, formatOrOptions);
  const formattedTill = formatTime(till, formatOrOptions);
  return `${formattedSince} – ${formattedTill}`;
};

/**
 * Gets number of minutes from seconds number
 */
export const secondsToMinutes = (seconds: number | undefined) => {
  if (R.isNil(seconds)) return undefined;
  return seconds / 60;
};

/**
 * Formats duration into x min y sec format.
 */
export const formatSecondsDuration = (seconds: number | undefined) => {
  const duration = dayjs.duration(seconds ?? 0, 'seconds');

  const secondsFormatString = duration.seconds() ? 's сек.' : '';
  const minutesFormatString = duration.minutes() ? 'm мин.' : '';

  return duration.format(`${minutesFormatString} ${secondsFormatString}`);
};
