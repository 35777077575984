import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { DateSwitcher } from '~/shared/components/DateSwitcher';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSingleSkeletonPlaceholder,
  isSkeletonPlaceholder,
  Skeleton,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { SPACED_INTERPUNCT } from '~/shared/constants';
import { formatDateForBackend, formatTimeRange } from '~/shared/helpers/date';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import { makeDeleteQuery } from '~/services/gql';
import { useConfirm } from '~/services/modals';
import { WithSearchParamsValidation } from '~/services/navigation';

import { PageHeader } from '~/features/layouts';

import {
  MilkingParlorIntervalPenGroupsTable,
  useApplyCutCodeModal,
  useEditMilkingParlorIntervalModal,
  useGetMilkingParlorInfo,
} from '~/widgets/milkingParlors';
import { useDeleteMilkingParlorIntervalMutation } from '~/widgets/milkingParlors/gql/mutations/deleteMilkingParlorInterval.graphql';
import { useMilkingParlorIntervalsQuery } from '~/widgets/milkingParlors/gql/queries/milkingParlorIntervals.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import styles from './index.module.scss';

interface MilkingParlorIntervalSearchParams {
  codesDate: string;
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/milking-parlors/intervals/$intervalId'
)({
  component: MilkingParlorIntervalPage,
  validateSearch: ({
    codesDate,
  }: WithSearchParamsValidation<MilkingParlorIntervalSearchParams>) => {
    const defaultCodesDate = formatDateForBackend(dayjs(), true);

    return {
      codesDate: codesDate ?? defaultCodesDate,
    };
  },
});

function MilkingParlorIntervalPage() {
  const navigate = Route.useNavigate();
  const { intervalId } = Route.useParams();

  const [deleteMilkingParlorInterval] =
    useDeleteMilkingParlorIntervalMutation();

  const getParlorInfo = useGetMilkingParlorInfo();

  const { data: milkingParlorIntervalsData, loading: isLoading } =
    useMilkingParlorIntervalsQuery({
      variables: {
        ids: normalizeToArrayOrUndefined(intervalId),
      },
    });
  const milkingParlorInterval =
    milkingParlorIntervalsData?.milkingParlorIntervals.nodes[0] ??
    getSingleSkeletonPlaceholder();

  const defaultCodesDate = formatDateForBackend(dayjs(), true);
  const { codesDate = defaultCodesDate, setCodesDate } =
    useSearchParamsState<typeof Route>();

  const { open: openEditMilkingParlorIntervalModal } =
    useEditMilkingParlorIntervalModal();

  const confirmDelete = useConfirm();

  const { open: openApplyCutCodeModal } = useApplyCutCodeModal();

  return (
    <div className={clsx(layoutStyles.limitedContainer, styles.root)}>
      <PageHeader
        {...{
          isLoading,
          title: `Доение ${formatTimeRange(
            milkingParlorInterval?.intervalStart,
            milkingParlorInterval?.intervalEnd
          )}`,
          description: (
            <>
              {milkingParlorInterval.milkingParlor?.farm.name}
              {SPACED_INTERPUNCT}
              {!isSkeletonPlaceholder(milkingParlorInterval) &&
                getParlorInfo(milkingParlorInterval.milkingParlor, true)}
            </>
          ),
          backLinkProps: {
            search: { farmId: milkingParlorInterval.milkingParlor?.farm.id },
            from: Route.fullPath,
            to: '../..',
          },
          rightContent: (
            <>
              <Button
                {...{
                  className: 'ml-a',
                  variant: ButtonVariants.secondary,
                  isDisabled: isLoading,
                  iconVariant: IconVariants.delete,
                  onPress: async () => {
                    if (isSkeletonPlaceholder(milkingParlorInterval)) return;

                    const isConfirmed = await confirmDelete({
                      title: 'Удаление доения',
                      message: (
                        <div className="grid gap-12">
                          <Typography
                            tag="p"
                            variant={TypographyVariants.bodySmall}
                          >
                            Вы хотите удалить время доения{' '}
                            <Typography
                              variant={TypographyVariants.bodySmallStrong}
                            >
                              {formatTimeRange(
                                milkingParlorInterval.intervalStart,
                                milkingParlorInterval.intervalEnd
                              )}
                            </Typography>{' '}
                            в{' '}
                            <Typography
                              variant={TypographyVariants.bodySmallStrong}
                            >
                              {getParlorInfo(
                                milkingParlorInterval.milkingParlor,
                                true
                              )}
                            </Typography>
                            ?
                          </Typography>
                          <Typography
                            tag="p"
                            variant={TypographyVariants.bodySmall}
                          >
                            Это действие невозможно отменить.
                          </Typography>
                        </div>
                      ),
                      isDelete: true,
                    });
                    if (!isConfirmed) return;

                    deleteMilkingParlorInterval({
                      variables: {
                        id: milkingParlorInterval.id,
                      },
                      optimisticResponse: { deleteMilkingParlorInterval: null },
                      update: makeDeleteQuery('milkingParlors'),
                    }).then(() => {
                      navigate({
                        to: '/$companyId/user/entities/milking-parlors',
                        search: {
                          farmId: milkingParlorInterval.milkingParlor?.farm.id,
                        },
                      });
                    });
                  },
                }}
              >
                Удалить
              </Button>
              <Button
                {...{
                  variant: ButtonVariants.secondary,
                  isDisabled: isLoading,
                  iconVariant: IconVariants.edit,
                  onPress: () => {
                    if (isSkeletonPlaceholder(milkingParlorInterval)) return;

                    openEditMilkingParlorIntervalModal({
                      milkingParlorID: milkingParlorInterval.milkingParlor.id,
                      milkingParlorInterval,
                    });
                  },
                }}
              >
                Редактировать
              </Button>
              <Button
                {...{
                  isDisabled: isLoading,
                  iconVariant: IconVariants.code,
                  onPress: () => {
                    if (isSkeletonPlaceholder(milkingParlorInterval)) return;

                    openApplyCutCodeModal({
                      milkingParlorInterval,
                      codesDate,
                      sortingGatesCount:
                        milkingParlorInterval.milkingParlor?.sortingGatesCount,
                    });
                  },
                }}
              >
                Присвоить код сортировки
              </Button>
            </>
          ),
        }}
      />
      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <Skeleton isLoading={isLoading}>
          <div className="flex items-center mb-24">
            <DateSwitcher
              {...{
                className: styles.filter,
                label: 'Дата',
                name: 'codesDate',
                value: codesDate,
                onValueChange: newDate =>
                  setCodesDate(formatDateForBackend(newDate, true)),
              }}
            />
          </div>
          <MilkingParlorIntervalPenGroupsTable
            {...{
              milkingParlorInterval,
              codesDate,
            }}
          />
        </Skeleton>
      </div>
    </div>
  );
}
