import React, { useMemo } from 'react';

import clsx from 'clsx';
import R from 'ramda';

import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatTimeRange } from '~/shared/helpers/date';

import { formatPenGroup } from '~/entities/penGroups';
import { PenGroupShortFragment } from '~/entities/penGroups/gql/fragments/penGroupShort.graphql';

import { MilkingParlorDetailedFragment } from '../../gql/fragments/milkingParlorDetailed.graphql';
import { MilkingParlorIntervalDetailedFragment } from '../../gql/fragments/milkingParlorIntervalDetailed.graphql';
import { useGetMilkingParlorInfo } from '../../hooks';
import { MilkingNumberSelect } from './components/MilkingNumberSelect';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Parlors with detailed info to render in the table
   */
  milkingParlors: (MilkingParlorDetailedFragment | SkeletonPlaceholder)[];
}

const INTERVALS_BY_PARLOR_COLUMNS_SKELETONS_COUNT = 2;
const PEN_GROUP_BY_INTERVAL_ROWS_SKELETONS_COUNT = 2;

const PEN_GROUP_COLUMN_WIDTH_PX = 204;
const INTERVAL_COLUMN_WIDTH_PX = 104;

// Helper structure dict by interval id and pen group id for easy access to milking number
type MilkingNumbersDict = Record<
  string,
  Record<string, number | null | undefined>
>;

interface TableColumnData {
  interval: MilkingParlorIntervalDetailedFragment;
  parlor: MilkingParlorDetailedFragment | SkeletonPlaceholder;
}

export const MilkingsScheduleTable: React.FC<Props> = ({
  className,
  milkingParlors,
}) => {
  const getParlorInfo = useGetMilkingParlorInfo();

  type TableRow = PenGroupShortFragment | SkeletonPlaceholder;

  const { milkingNumbersDict, uniqPenGroups } = useMemo(() => {
    const tableColumnData = milkingParlors.flatMap<
      TableColumnData | SkeletonPlaceholder
    >(
      parlor =>
        parlor.intervals?.map(interval => ({ interval, parlor })) ??
        getSkeletonPlaceholders(INTERVALS_BY_PARLOR_COLUMNS_SKELETONS_COUNT)
    );

    const milkingNumbersDictInternal =
      tableColumnData.reduce<MilkingNumbersDict>((acc, columnData) => {
        if (isSkeletonPlaceholder(columnData)) {
          return acc;
        }
        const { interval } = columnData;
        acc[interval.id] = acc[interval.id] ?? {};

        interval.penGroups.edges.reduce((penGroupAcc, penGroup) => {
          penGroupAcc[penGroup.node.id] = penGroup.milkingNumber;
          return penGroupAcc;
        }, acc[interval.id]);

        return acc;
      }, {});

    const uniqPenGroupsInternal = R.uniqBy(
      penGroup => penGroup.id ?? Math.random(),
      tableColumnData.flatMap<TableRow>(
        columnData =>
          columnData.interval?.penGroups.edges.map(R.prop('node')) ??
          getSkeletonPlaceholders(PEN_GROUP_BY_INTERVAL_ROWS_SKELETONS_COUNT)
      )
    );

    return {
      milkingNumbersDict: milkingNumbersDictInternal,
      uniqPenGroups: uniqPenGroupsInternal,
    };
  }, [milkingParlors]);

  const columnConfigs: TableColumnConfig<TableRow>[] = [
    {
      title: 'Группа',
      key: 'penGroup',
      renderCellContent: penGroup => formatPenGroup(penGroup),
      width: PEN_GROUP_COLUMN_WIDTH_PX,
    },
    ...milkingParlors.map<TableColumnConfig<TableRow>>(parlor => {
      const parlorIntervals =
        parlor.intervals ??
        getSkeletonPlaceholders(INTERVALS_BY_PARLOR_COLUMNS_SKELETONS_COUNT);

      return {
        key: parlor.id,
        headerTypographyProps: {
          isStaticContent: true,
        },
        title: (
          <div className="grid justify-items-center">
            <Typography
              {...{
                className: 'full-width text-center ellipsis',
                variant: TypographyVariants.descriptionLargeStrong,
                skeletonProps: {
                  className: 'py-4',
                },
              }}
            >
              {parlor.name}
            </Typography>
            <Typography
              {...{
                className: 'full-width text-center text-soft ellipsis',
                variant: TypographyVariants.descriptionLarge,
                skeletonProps: {
                  className: 'py-4',
                  width: '100%',
                },
              }}
            >
              {!isSkeletonPlaceholder(parlor) && getParlorInfo(parlor)}
            </Typography>
          </div>
        ),
        nestedColumns: parlorIntervals.map<TableColumnConfig<TableRow>>(
          interval => ({
            key: interval.id,
            title: formatTimeRange(
              interval.intervalStart,
              interval.intervalEnd
            ),
            cellClassName: 'py-0',
            width: INTERVAL_COLUMN_WIDTH_PX,
            cellTypographyProps: {
              skeletonProps: {
                width: '100%',
              },
            },

            renderCellContent: penGroup => {
              if (
                isSkeletonPlaceholder(interval) ||
                isSkeletonPlaceholder(penGroup)
              ) {
                return null;
              }

              // Don't show the select, if the current group is not in the interval
              if (
                !interval.penGroups.edges.find(
                  intervalGroup => intervalGroup.node.id === penGroup.id
                )
              ) {
                return '';
              }

              const currentMilkingNumber =
                milkingNumbersDict[interval.id][penGroup.id];

              return (
                <MilkingNumberSelect
                  {...{
                    intervalId: interval.id,
                    penGroupId: penGroup.id,
                    value: currentMilkingNumber,
                  }}
                />
              );
            },
          })
        ),
      };
    }),
    FILLER_COLUMN_CONFIG,
  ];

  return (
    <Table<TableRow>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items: uniqPenGroups,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
      }}
    />
  );
};
