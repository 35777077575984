import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintRoleFragmentDoc } from '../../../../services/auth/gql/fragments/blueprintRole.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBlueprintRoleMutationVariables = Types.Exact<{
  input: Types.CreateBlueprintRoleInput;
}>;


export type CreateBlueprintRoleMutation = { __typename?: 'Mutation', createBlueprintRole: { __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor } };


export const CreateBlueprintRoleDocument = gql`
    mutation createBlueprintRole($input: CreateBlueprintRoleInput!) {
  createBlueprintRole(input: $input) {
    ...BlueprintRole
  }
}
    ${BlueprintRoleFragmentDoc}`;
export type CreateBlueprintRoleMutationFn = Apollo.MutationFunction<CreateBlueprintRoleMutation, CreateBlueprintRoleMutationVariables>;

/**
 * __useCreateBlueprintRoleMutation__
 *
 * To run a mutation, you first call `useCreateBlueprintRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlueprintRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlueprintRoleMutation, { data, loading, error }] = useCreateBlueprintRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBlueprintRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlueprintRoleMutation, CreateBlueprintRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlueprintRoleMutation, CreateBlueprintRoleMutationVariables>(CreateBlueprintRoleDocument, options);
      }
export type CreateBlueprintRoleMutationHookResult = ReturnType<typeof useCreateBlueprintRoleMutation>;
export type CreateBlueprintRoleMutationResult = Apollo.MutationResult<CreateBlueprintRoleMutation>;
export type CreateBlueprintRoleMutationOptions = Apollo.BaseMutationOptions<CreateBlueprintRoleMutation, CreateBlueprintRoleMutationVariables>;