import { ConceptionRateParameterEnum } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT } from '../../constants';

const useReproductionConceptionRateParameterSelect = makeUseEnumSelect(
  ConceptionRateParameterEnum,
  enumValue =>
    REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT[
      enumValue as ConceptionRateParameterEnum
    ]
);

export const ReproductionConceptionRateParameterSelect =
  makeSelectComponentFromHook(useReproductionConceptionRateParameterSelect);
