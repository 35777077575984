import React from 'react';

import R from 'ramda';

import { ButtonVariants } from '~/shared/components/Button';
import {
  isSkeletonPlaceholder,
  Skeleton,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { formatDateRange } from '~/shared/helpers/date';

import {
  getBackendInputByValueKind,
  readSourceFieldFragment,
} from '~/entities/blueprintSourceFields';
import { CustomMilkingReportDetailedFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReportDetailed.graphql';
import { useCalculateCustomMilkingReportMutation } from '~/entities/customMilkingReports/gql/mutations/calculateCustomMilkingReport.graphql';
import { useSetCustomMilkingReportSettingsMutation } from '~/entities/customMilkingReports/gql/mutations/setCustomMilkingReportSettings.graphql';

import {
  ReportCardBuilderCard,
  useReportCardBuilder,
} from '~/features/reportCardBuilder';

import { CUSTOM_MILKING_REPORT_SETTINGS_FORM_ID } from '../../constants';
import {
  isCustomMilkingReportDataEmpty,
  mapCustomMilkingReportSettingsToForm,
} from '../../helpers';
import {
  CustomMilkingReportSettingsFormType,
  FiltersAndGroupingsModes,
} from '../../types';
import { CustomMilkingReportData } from '../CustomMilkingReportData';
import { CustomMilkingReportSettingsForm } from '../CustomMilkingReportSettingsForm';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to take settings from
   */
  customMilkingReport:
    | CustomMilkingReportDetailedFragment
    | SkeletonPlaceholder;
}

export const CustomMilkingReportCard: React.FC<Props> = ({
  className,
  customMilkingReport,
}) => {
  const [
    setCustomMilkingReportSettings,
    { loading: isSetCustomMilkingReportSettingsLoading, client },
  ] = useSetCustomMilkingReportSettingsMutation();

  const [
    calculateCustomMilkingReport,
    { reset: resetCalculateCustomMilkingReportMutation },
  ] = useCalculateCustomMilkingReportMutation();

  const mapCustomReportSettingsFormToBackendInput = (
    settingsForm: CustomMilkingReportSettingsFormType
  ) => ({
    ...R.omit(['period', 'filters', 'grouping'], settingsForm),
    ...settingsForm.period,
    grouping:
      settingsForm.grouping &&
      (settingsForm.grouping.mode === FiltersAndGroupingsModes.actual
        ? {
            masterBlueprintSourceFieldID:
              settingsForm.grouping.masterBlueprintSourceFieldID,
          }
        : {
            kind: settingsForm.grouping.kind,
          }),
    filters: settingsForm.filters.map(filterConfig => {
      if (
        filterConfig.mode === FiltersAndGroupingsModes.actual &&
        filterConfig.actualFilter
      ) {
        const {
          masterBlueprintSourceFieldID,
          value: masterBlueprintSourceFieldValue,
        } = filterConfig.actualFilter;

        const selectedSourceField = readSourceFieldFragment(
          client,
          masterBlueprintSourceFieldID
        );

        return {
          actualFilter: {
            masterBlueprintSourceFieldID,
            value:
              selectedSourceField &&
              getBackendInputByValueKind(
                selectedSourceField.returnValueKind,
                masterBlueprintSourceFieldValue
              ),
          },
        };
      }

      return {
        historicFilter: {
          kind: filterConfig.historicFilter?.kind ?? null,
          value: filterConfig.historicFilter?.value ?? null,
        },
      };
    }),
  });

  const {
    currentReportData,

    initialSettings,
    requestedSettings,

    setCurrentReportData,

    customReportCardProps,
    customReportSettingsFormProps,
  } = useReportCardBuilder({
    reportDeps: [customMilkingReport],

    getReportData: ([customMilkingReportDep]) =>
      customMilkingReportDep.calculatedReport,
    getReportSettings: ([customMilkingReportDep]) =>
      customMilkingReportDep.settings,
    mapReportSettingsToForm: mapCustomMilkingReportSettingsToForm,

    getIsReportEmpty: milkingReportData =>
      isCustomMilkingReportDataEmpty(milkingReportData),
    emptyReportData: {
      __typename: 'CustomMilkingReportChartEmpty',
    },

    calculateReport: (settingsFormValues, mutationContext) =>
      calculateCustomMilkingReport({
        variables: {
          id: customMilkingReport.id,
          settings:
            mapCustomReportSettingsFormToBackendInput(settingsFormValues),
        },
        context: mutationContext,
      }).then(({ data }) => {
        if (data?.calculateCustomMilkingReport.__typename) {
          setCurrentReportData(data.calculateCustomMilkingReport);
        }
      }),

    setReportSettings: settingsForm =>
      setCustomMilkingReportSettings({
        variables: {
          id: customMilkingReport.id,
          input: mapCustomReportSettingsFormToBackendInput(settingsForm),
        },
      }),

    onCardEditCancel: resetCalculateCustomMilkingReportMutation,
  });

  return (
    <ReportCardBuilderCard
      {...{
        className,
        title: formatDateRange(initialSettings?.since, initialSettings?.till),
        updatingTitle: 'Настройки графика и таблицы',
        ...customReportCardProps,
        settingsButtonProps: {
          variant: ButtonVariants.primary,
          children: 'Настройки',
        },
        submitButtonProps: {
          form: CUSTOM_MILKING_REPORT_SETTINGS_FORM_ID,
          isLoading: isSetCustomMilkingReportSettingsLoading,
        },
        renderEditingContent: () =>
          !isSkeletonPlaceholder(customMilkingReport) && (
            <CustomMilkingReportSettingsForm
              {...{
                customMilkingReport,
                ...customReportSettingsFormProps,
              }}
            />
          ),
      }}
    >
      <Skeleton isLoading={isSkeletonPlaceholder(currentReportData)}>
        <CustomMilkingReportData
          {...{
            reportData: currentReportData,
            requestedSettings,
          }}
        />
      </Skeleton>
    </ReportCardBuilderCard>
  );
};
