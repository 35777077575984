import React from 'react';

import clsx from 'clsx';

import {
  SkeletonPlaceholder,
  TextSkeletonSizes,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { DateRangeFormats, formatDateRange } from '~/shared/helpers/date';
import { capitalize } from '~/shared/helpers/string';

import { LivestockForecastPeriodFragment } from '~/widgets/livestockForecast/gql/fragments/livestockForecastPeriod.graphql';

import TOKENS from '~/styles/__generated__/tokens.json';
import panelStyles from '~/styles/modules/panel.module.scss';

import { getMilkValue } from '../../helpers';
import { CardRow } from './components/CardRow';
import { PercentsBar } from './components/PercentsBar';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Period to render
   */
  period: LivestockForecastPeriodFragment | SkeletonPlaceholder;
}

const COW_MILKING_COLOR = TOKENS.colorSuccessSoft;
const COW_DRY_COLOR = TOKENS.colorWarningSoft;

export const LivestockForecastPeriodCard: React.FC<Props> = ({
  className,
  period,
}) => {
  const { getSkeletonClassNames } = useSkeletonContext();

  return (
    <div className={clsx(styles.root, panelStyles.largePanel, className)}>
      <div className={styles.section}>
        <Typography tag="h3" variant={TypographyVariants.bodyLargeStrong}>
          {capitalize(
            formatDateRange(
              period.since,
              period.till,
              DateRangeFormats.monthsAndSingleYear
            )
          )}
        </Typography>
        <Typography
          {...{
            variant: TypographyVariants.descriptionLarge,
            className: clsx('text-soft', getSkeletonClassNames('mt-8 py-2')),
            skeletonSize: TextSkeletonSizes.large,
          }}
        >
          Средние значения за месяц
        </Typography>
        <CardRow
          {...{
            label: 'Дойные',
            className: getSkeletonClassNames('mt-24 mb-8', 'mt-24 mb-4'),
            value: period.cowsStatusCount?.milking,
            dotColor: COW_MILKING_COLOR,
          }}
        />
        <CardRow
          {...{
            label: 'Сухостойные',
            value: period.cowsStatusCount?.allDry,
            dotColor: COW_DRY_COLOR,
          }}
        />
        <PercentsBar
          {...{
            className: getSkeletonClassNames('mt-24', 'mt-16'),
            total: period.cowsStatusCount?.total,
            leftValue: period.cowsStatusCount?.milking,
            leftColor: COW_MILKING_COLOR,
            rightColor: COW_DRY_COLOR,
          }}
        />
      </div>
      <div
        className={clsx(styles.borderedSection, getSkeletonClassNames('py-20'))}
      >
        <CardRow
          {...{
            label: 'Отёлы',
            className: getSkeletonClassNames('mb-8', 'mb-4'),
            value: period.calvingCount?.total,
            typographyVariant: TypographyVariants.bodySmallStrong,
          }}
        />
        <CardRow
          {...{
            label: 'Нетели',
            className: getSkeletonClassNames('mb-8', 'mb-4'),
            value: period.calvingCount?.heifers,
          }}
        />
        <CardRow
          {...{
            label: 'Коровы',
            value: period.calvingCount?.cows,
          }}
        />

        <CardRow
          {...{
            label: 'Запуск в сухостой',
            className: getSkeletonClassNames('my-24', 'my-16'),
            value: period.dryStartCount,
            typographyVariant: TypographyVariants.bodySmallStrong,
          }}
        />

        <CardRow
          {...{
            label: 'Ожидаемое выбытие коров',
            className: getSkeletonClassNames('mb-8', 'mb-4'),
            value: period.expectedRetiredCount?.total,
            typographyVariant: TypographyVariants.bodySmallStrong,
          }}
        />
        <CardRow
          {...{
            label: 'Первотёлки',
            className: getSkeletonClassNames('mb-8', 'mb-4'),
            value: period.expectedRetiredCount?.firstLact,
          }}
        />
        <CardRow
          {...{
            label: 'Коровы, лактация ≥ 2',
            value: period.expectedRetiredCount?.otherLact,
          }}
        />

        <CardRow
          {...{
            label: 'Ожидается тёлок',
            className: getSkeletonClassNames('mt-24', 'mt-16'),
            value: period?.expectedNewbornHeifersCount,
            typographyVariant: TypographyVariants.bodySmallStrong,
          }}
        />
      </div>
      <div className={clsx(styles.section, getSkeletonClassNames('py-20'))}>
        <Typography variant={TypographyVariants.bodySmallStrong}>
          Надой
        </Typography>
        <Typography
          {...{
            variant: TypographyVariants.descriptionLarge,
            className: clsx('text-soft', getSkeletonClassNames('mt-8 py-2')),
            skeletonSize: TextSkeletonSizes.large,
          }}
        >
          Средние значения за день
        </Typography>

        <CardRow
          {...{
            label: 'На голову, кг',
            className: getSkeletonClassNames('mt-16 mb-8', 'mt-12 mb-4'),
            value: period.cowsMilking?.perHead,
          }}
        />
        <CardRow
          {...{
            label: 'На стадо, т',
            value: getMilkValue(period?.cowsMilking, 'perHerd'),
            isFloat: true,
          }}
        />
      </div>
    </div>
  );
};
